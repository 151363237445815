import * as constants from "../constants";

const getSponsorsListLoading = (state) => ({
  ...state,
  sponsorsListLoading: !state.sponsorsListLoading,
});

const addSponsorLoading = (state) => ({
  ...state,
  addSponsorLoading: !state.addSponsorLoading,
});

export const loadingHandlers = {
  [constants.GET_SPONSORS_LIST_LOADING]: getSponsorsListLoading,
  [constants.ADD_SPONSOR_LOADING]: addSponsorLoading,
};
