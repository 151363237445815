export const SET_CREATE_COURSE_LOADING = "SET_CREATE_COURSE_LOADING"
export const SET_COURSES_LIST = "SET_COURSES_LIST";
export const GET_COURSES_LIST_LOADING = "GET_COURSES_LIST_LOADING"
export const SET_COURSE_DETAILS = "SET_COURSE_DETAILS"
export const GET_COURSE_DETAILS_LOADING = "GET_COURSE_DETAILS_LOADING"
export const SET_CREATE_SECTION_LOADING = "SET_CREATE_SECTION_LOADING"
export const SET_SECTIONS_LIST = "SET_SECTIONS_LIST";
export const GET_SECTIONS_LIST_LOADING = "GET_SECTIONS_LIST_LOADING"
export const SET_SECTION_DETAILS = "SET_SECTION_DETAILS"
export const GET_SECTION_DETAILS_LOAD = "GET_SECTION_DETAILS_LOAD"
export const SET_CREATE_LESSON_LOADING = "SET_CREATE_LESSON_LOADING"
export const GET_LESSONS_LIST_LOADING = "GET_LESSONS_LIST_LOADING"
export const SET_LESSONS_LIST = "SET_LESSONS_LIST";
export const GET_LESSON_DETAILS_LOADING = "GET_LESSON_DETAILS_LOADING"
export const SET_LESSON_DETAILS = "SET_LESSON_DETAILS"
export const SET_LESSON_NAME = "SET_LESSON_NAME"
export const SET_USER_COURSES_LIST = "SET_USER_COURSES_LIST"
export const GET_USER_COURSES_LIST_LOADING = "GET_USER_COURSES_LIST_LOADING"
export const GET_COURSE_PROGRESS_LOAD = "GET_COURSE_PROGRESS_LOAD"
export const SET_COURSE_PROGRESS = "SET_COURSE_PROGRESS"
export const GET_USER_PROGRESS_LOAD = "GET_USER_PROGRESS_LOAD"
export const SET_USER_PROGRESS = "SET_USER_PROGRESS"
export const SET_COURSE_CURRICULUM = "SET_COURSE_CURRICULUM"
export const GET_COURSE_CURRICULUM_LOAD ="GET_COURSE_CURRICULUM_LOAD"
export const GET_COURSE_USER_FORM_FIELD_LOADING ="GET_COURSE_USER_FORM_FIELD_LOADING"
export const SET_COURSE_USER_ID = "SET_COURSE_USER_ID"
//----------------COURSE STUDENTS----------------------
export const SET_COURSE_USERS_LIST ="SET_COURSE_USERS_LIST"
export const GET_COURSE_USERS_LIST_LOADING = "GET_COURSE_USERS_LIST_LOADING"
export const GET_USERS_BY_COURSE_LIST_LOADING ="GET_USERS_BY_COURSE_LIST_LOADING"
export const SET_USERS_BY_COURSE_LIST ="SET_USERS_BY_COURSE_LIST"

// --Content---
export const SET_CREATE_CONTENT_LOADING = "SET_CREATE_CONTENT_LOADING"
export const GET_CONTENTS_LIST_LOADING = "GET_CONTENTS_LIST_LOADING"
export const SET_CONTENTS_LIST = "SET_CONTENTS_LIST";
export const GET_CONTENT_DETAILS_LOADING = "GET_CONTENT_DETAILS_LOADING"
export const SET_CONTENT_DETAILS = "SET_CONTENT_DETAILS"