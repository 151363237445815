import * as constants from "../constants";

const setRoleLoading = (state) => ({
  ...state,
  roleDetailsLoading: !state.roleDetailsLoading,
})

const setRolesLoading = (state) => ({
  ...state,
  rolesLoading:!state.rolesLoading,
})
const setRoleFromFieldLoading = (state) => ({
  ...state,
  addRoleFormFieldLoading: !state.addRoleFormFieldLoading,
})

const  setDeleteRoleLoading = (state) => ({
  ...state,
  deleteRoleLoading: !state.deleteRoleLoading,
})

const  setViewRoleLoading = (state) => ({
  ...state,
  viewRoleLoading: !state.viewRoleLoading,
})

const setPermissionLoading = (state) => ({
  ...state,
  permissionLoading: !state.permissionLoading
})

const setPermissionsLoading = (state) => ({
  ...state,
  permissionsLoading: !state.permissionsLoading
})

const  setDeletePermissionLoading = (state) => ({
  ...state,
  deletePermissionLoading: !state.deletePermissionLoading,
})

const setUsersListLoading =(state) => ({
  ...state,
  usersListLoading:!state.usersListLoading,
})
export const setUserRoleHandlers = {
  [constants.SET_ROLE_LOADING] : setRoleLoading,
  [constants.SET_ROLE_FORM_FIELD_LOADING] : setRoleFromFieldLoading,
  [constants.VIEW_ROLE_LOADING] : setViewRoleLoading,
  [constants.SET_ROLES_LOADING] : setRolesLoading,
  [constants.DELETE_ROLE_LOADING] : setDeleteRoleLoading,
  [constants.SET_PERMISSION_LOADING] : setPermissionLoading,
  [constants.SET_PERMISSIONS_LOADING] : setPermissionsLoading,
  [constants.DELETE_PERMISSION_LOADING] : setDeletePermissionLoading,
  [constants.USERS_LIST_LOADING]:setUsersListLoading,
};
