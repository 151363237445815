import * as constants from "../constants";

const setSponsorsList = (state, action) => {
  return {
    ...state,
    sponsorsList: action.payload.Sponsors,
    sponsorsCount: action.payload.count,
  }
};

export const dataHandlers = {
  [constants.SET_SPONSORS_LIST]: setSponsorsList,
};
