export const SET_EVENT_REGISTRATION_LOADING = "SET_EVENT_REGISTRATION_LOADING";
export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const SET_EVENT_COUNT_DETAILS = "SET_EVENT_COUNT_DETAILS";
export const GET_EVENT_DETAILS_LOADING = "GET_EVENT_DETAILS_LOADING";
export const SET_EDIT_EVENT_LOADING = "SET_EDIT_EVENT_LOADING";
export const GET_EVENTS_LIST_LOADING = "GET_EVENTS_LIST_LOADING";
export const SET_EVENTS_LIST = "SET_EVENTS_LIST";
export const SET_EVENTS_UPCOMING_LIST = "SET_EVENTS_UPCOMING_LIST";
export const SET_EVENTS_PAST_LIST = "SET_EVENTS_PAST_LIST";
export const SET_EVENTS_LIVE_LIST = "SET_EVENTS_LIVE_LIST";
export const SET_CLEAR_EVENTS_LIST = "SET_CLEAR_EVENTS_LIST";
export const SET_SEARCH_QUERY_OBJECT = "SET_SEARCH_QUERY_OBJECT";
export const SET_SEARCH_EVENTS_LIST = "SET_SEARCH_EVENTS_LIST";
export const SET_SEARCH_EVENTS_LIST_LOADING = "SET_SEARCH_EVENTS_LIST_LOADING";
export const SET_SHOW_SEARCH_RESULT_PAGE = "SET_SHOW_SEARCH_RESULT_PAGE";
export const GET_EVENT_REGISTRATIONS_LOADING =
  "GET_EVENT_REGISTRATIONS_LOADING";
export const SET_EVENT_REGISTRATIONS_LIST = "SET_EVENT_REGISTRATIONS_LIST";
// Event Form Fileds
export const SET_EVENT_FORM_FIELD_LOADING = "SET_EVENT_FORM_FIELD_LOADING";
export const GET_EVENT_FORM_FIELDS_LOADING = "GET_EVENT_FORM_FIELDS_LOADING";
export const SET_EVENT_FORM_FIELDS = "SET_EVENT_FORM_FIELDS";
export const EDIT_EVENT_FORM_FIELD_LOADING = "EDIT_EVENT_FORM_FIELD_LOADING";
export const DELETE_EVENT_FORM_FIELD_LOADING =
  "DELETE_EVENT_FORM_FIELD_LOADING";
export const SET_EVENT_TICKET = "SET_EVENT_TICKET";

// Event Timeline
export const SET_EVENT_TIMELINE_LOADING = "SET_EVENT_TIMELINE_LOADING";
export const GET_EVENT_TIMELINES_LOADING = "GET_EVENT_TIMELINES_LOADING";
export const GET_EVENT_TIMELINE_DETAILS_LOADING =
  "GET_EVENT_TIMELINE_DETAILS_LOADING";
export const SET_EVENT_TIMELINES = "SET_EVENT_TIMELINES";
export const EDIT_EVENT_TIMELINE_LOADING = "EDIT_EVENT_TIMELINE_LOADING";
export const DELETE_EVENT_TIMELINE_LOADING = "DELETE_EVENT_TIMELINE_LOADING";
// Event Speaker
export const SET_EVENT_SPEAKER_LOADING = "SET_EVENT_SPEAKER_LOADING";
export const GET_EVENT_SPEAKERS_LOADING = "GET_EVENT_SPEAKERS_LOADING";
export const GET_EVENT_SPEAKER_DETAILS_LOADING =
  "GET_EVENT_SPEAKER_DETAILS_LOADING";
export const SET_EVENT_SPEAKERS = "SET_EVENT_SPEAKERS";
export const SET_EVENT_SPEAKER = "SET_EVENT_SPEAKER"
export const EDIT_EVENT_SPEAKER_LOADING = "EDIT_EVENT_SPEAKER_LOADING";
export const DELETE_EVENT_SPEAKER_LOADING = "DELETE_EVENT_SPEAKER_LOADING";
// Event Sponsor
export const SET_EVENT_SPONSOR_LOADING = "SET_EVENT_SPONSOR_LOADING";
export const GET_EVENT_SPONSORS_LOADING = "GET_EVENT_SPONSORS_LOADING";
export const GET_EVENT_SPONSOR_DETAILS_LOADING =
  "GET_EVENT_SPONSOR_DETAILS_LOADING";
export const SET_EVENT_SPONSORS = "SET_EVENT_SPONSORS";
export const EDIT_EVENT_SPONSOR_LOADING = "EDIT_EVENT_SPONSOR_LOADING";
export const DELETE_EVENT_SPONSOR_LOADING = "DELETE_EVENT_SPONSOR_LOADING";
