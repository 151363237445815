import { loadingHandlers } from './loadingHandlers'
import { dataHandlers } from './dataHandlers'
import { createReducer } from '../../../core/reduxUtils'

const initialState = {
  currentUser: {
    userName: "",
    email: "",
    _id: "",
    token: "",
    courseUser: "",
    roles: [],
    createdAt: "",
    email_code: "",
    email_verify: "",
    followers: [],
    myTutorialsLibrary: [],
    password: "",
    updatedAt: "",
  },
  loginUserError: "",
  loginUserLoading: false,
  loginStatus: false,
  userProfileLoading: false,
  usersListLoading: false,
  usersData: [],
  userProfile: {},
  reviewsLoading: false,
  reviewsList: [],
  reviewToBeEdited: {}
}

const handlers = {
  ...loadingHandlers,
  ...dataHandlers,
};



const authReducer = createReducer(initialState, handlers);

export default authReducer;