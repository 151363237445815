export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LOGIN_USER_LOADING = "SET_LOGIN_USER_LOADING";
export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const SET_LOGIN_USER_ERROR = "SET_LOGIN_USER_ERROR";
export const SET_LOGIN_USER_STATUS = "SET_LOGIN_USER_STATUS";
export const SET_LOGOUT_USER = "SET_LOGOUT_USER";
export const SET_USER_PROFILE_LOADING = "SET_USER_PROFILE_LOADING";
export const SET_USERS_PROFILE_LIST = "SET_USERS_PROFILE_LIST";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_REVIEW_LIST_LOADING = "SET_REVIEW_LIST_LOADING";
export const SET_REVIEW_LIST = "SET_REVIEW_LIST";
export const SET_EDIT_REVIEW = "SET_EDIT_REVIEW";
export const CLEAR_EDIT_REVIEW = "CLEAR_EDIT_REVIEW";
export const SET_USERS_LIST_LOADING = "SET_USERS_LIST_LOADING";