import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import './styles.css';
import { getColumns } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { deletePermission } from '../../actions/operations';


const PermissionListTable = ({setIsEdit ,isEdit , permissionss ,setTempId ,setShowModal,tempId}) => {
  const{permissions} = useSelector((state)=>state.role)
  const dispatch = useDispatch();
  const handleDelete = () =>{
    dispatch(deletePermission(tempId));
  }
  const handleUpdate = () =>{
    setIsEdit(true);
    setShowModal(true);

  }
  const items = [
    {
      key: '1',
      label: (
        <div onClick={()=>handleUpdate()}>Update</div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={(e)=>{
          e.preventDefault();
          handleDelete()}}> Delete</div>
      ),
    },
  ];
  let columns = getColumns({items ,setTempId})
  
  return (
    <>
      {permissions &&
        <div className="table table-responsive">
          <BootstrapTable
            keyField='id'
            data={permissions}
            columns={columns}
            bordered={false}
            hover
            condensed
            headerClasses="header-class"
            rowClasses="row-class"
          />
        </div>
      }
    </>
  )
}

export default PermissionListTable
