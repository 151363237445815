import { useEffect, useState } from "react";
import { roleStatusOptions } from "../../../../DaysCode/components/Settings/helpers";
import { getOptions } from "../../../../../components/utils";
import { Combobox } from "react-widgets";
import { FormGroup, Label, Input, Alert } from 'reactstrap';


const FilterPopup = ({openFilter,setOpenFilter}) => {
   const [selectedStatus , setSelectedStatus] = useState("")
   const [selectedOptions, setSelectedOptions] = useState({
    author: false,
    customer: true,
  });
  const [error, setError] = useState('');


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked
    }));
    setError(''); // Clear error when a checkbox is changed
  };
  const handleCancel = () => {
    setSelectedStatus();
    setSelectedOptions({
      author: false,
      customer: true,
    });
    // setIsOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
/*     const isAnyChecked = Object.values(selectedOptions).some((value) => value); */
    setOpenFilter(!openFilter);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".filter-popup")) {
      setOpenFilter(false); // close the modal when clicking outside
    }
  };

  useEffect(() => {
    if (openFilter) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openFilter]);
  return (
    <>
    {openFilter?(
    <div className="filter-card p-4">
    <div className="filter-header d-flex align-items-center justify-content-space-around">Filter Options</div>
    <hr/>
      <div className='mb-2 '>
                  <label className=' form-label filter-header d-flex align-items-center justify-content-space-around'>Status:</label>
                  <Combobox
                    data={getOptions(roleStatusOptions,'status')}
                    dataKey={"value"}
                    textField='status'
                    placeholder={"Select Option"}
                    value={selectedStatus}
                    onChange={(value)=>{setSelectedStatus(value)}}
                  />
      </div>
          <div className="mt-3">
          <FormGroup>
          <legend className=" mb-1 d-flex filter-header ">Member Type</legend>
          <div className="d-flex ">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="author"
                  checked={selectedOptions.author}
                  onChange={handleCheckboxChange}
                />
                Author
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="customer"
                  checked={selectedOptions.customer}
                  onChange={handleCheckboxChange}
                />
                Customer
              </Label>
            </FormGroup>
          </div>
        </FormGroup> </div>
        {error && <Alert color="danger">{error}</Alert>}
       
      <div className='mt-4 filter-buttons'>
                <button
                  className='btn btn-secondary mx-2'
                  onClick={handleCancel}
                >
                  Reset
                </button>
                <button className='btn btn-primary mx-2' onClick={(e)=>handleSubmit(e)}>
                  Apply
                </button>
              </div>
          </div>):("")}
    
    </>
  );
};

export default FilterPopup;
