import { loadingHandlers } from "./loadingHandlers";
import { dataHandlers } from "./dataHandlers";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentUser: {
    userName: "akshay@25",
    email: "akshaycse25@gmail.com"
  },
  speakersListLoading: false,
  eventSpeakerFilterLoadinge: false,
  addSpeakerLoading: false,
  deleteSpeakerLoading: false,
  speakerDetailsLoading: false,
  speakersCount: 0,
  speakersList: [],
  speakerDetails: {},
};

const handlers = {
  ...loadingHandlers,
  ...dataHandlers,
};

const speakerReducer = createReducer(initialState, handlers);

export default speakerReducer;
