import {  useState } from "react";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import DeleteModal from "../../../../../components/sharedComponents/DeleteModal";
import { deleteRole } from "../../../actions/operations";
import { Dropdown } from "antd";
import { FaEllipsisV } from "react-icons/fa";

const RoleListCard = ({role, handleIsEdit ,  handleIsView , isView,showModal ,setShowModal,toggleModal ,isEdit ,setIsEdit  ,id, setShowUserModal}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDelModal , setShowDelModal] = useState(false);
  
  const handleView = () =>{
    handleIsView();
    navigate(`/role/${role._id}`)
  }
  const toggleDel=()=>{
    setShowDelModal(!showDelModal);
    console.log(showDelModal);
  }
  const handleDel = () => {
    dispatch(deleteRole(id));
  }
  const items = [
    {
      key: '1',
      label: (
        <div onClick={handleView}>View</div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={toggleDel}> Delete</div>
      ),
    },
  ];

  return (
    <>
     <div className="row mx-0 mt-4 role-card">        
          <div className="col-10 card1-header d-flex  align-items-stretch flex-wrap ">{role && role?.roleName}</div>
    { role?.roleName==="Admin" ||isView ?(""):( <div className="col-2  d-flex justify-content-space-around align-items-center flex-wrap dot">  
    <Dropdown menu={{ items, }}>
      <div style={{backgroundColour:"white" , height:"25px"}} className="btn" onClick={(e) => e.preventDefault()}>
          <FaEllipsisV /> 
      </div>
    </Dropdown> </div>)}    
        <div className="col-12  card1-body">
            <div className="sub-header ">Total users with this role : 14</div>     
            <div className="row mx-0 d-flex flex-column ">
              <div className="col-12 feature"> <span className="bullet bg-primary"></span> All admin controls</div>
              <div className="col-12 feature"><span className="bullet bg-primary"></span> All admin controls</div>
              <div className="col-12 feature"><span className="bullet bg-primary"></span> All admin controls</div>
              <div className="col-12 feature"><span className="bullet bg-primary"></span> All admin controls</div>
              <div className="col-12 feature"><span className="bullet bg-primary"></span> All admin controls</div>
              <div className="col-12 feature"><span className="bullet bg-primary"></span> and 5 more...</div>
            </div>             
        </div>
        <div className="card-footer flex-wrap"> 
       {!isView ? (<button className="btn btn-light  my-1 mx-1 view-btn" onClick={handleView}>View Role</button>) 
       :(
        <button className="btn btn-light  my-1 mx-1 " onClick={()=>setShowUserModal(true)}>Assign User</button>
       )
       } <button className="btn btn-light  my-1 mx-1" onClick={handleIsEdit}>Edit Role</button>
         </div>  
     </div> 
     {<DeleteModal onSubmit={handleDel} toggle={toggleDel} title="Delete Role" open={showDelModal}/>}
    </>
  );
};

export default RoleListCard;
