import * as constants from "../constants";

const setCoursesList = (state, action) => ({
  ...state,
  coursesList: action.payload.courses,
  coursesCount: action.payload.count,
  coursesListLoading: !state.coursesListLoading,
});

const getCoursesListLoading = (state) => ({
  ...state,
  coursesListLoading: !state.coursesListLoading,
});

const setCourseDetails = (state, action) => ({
  ...state,
  courseDetails: action.payload,
});

const getCourseDetailsLoading = (state) => ({
  ...state,
  courseDetailsLoading: !state.courseDetailsLoading,
});

const setCreateCourseLoading = (state) => ({
  ...state,
  createCourseLoading: !state.createCourseLoading,
});

const setCreateSectionLoading = (state) => ({
  ...state,
  createSectionLoading: !state.createSectionLoading,
});

const setSectionsList = (state, action) => ({
  ...state,
  sectionsList: action.payload.sections,
  sectionsCount: action.payload.count,
  sectionsListLoading: !state.sectionsListLoading,
});

const getSectionsListLoading = (state) => ({
  ...state,
  sectionsListLoading: !state.sectionsListLoading,
});

const setSectionDetails = (state, action) => ({
  ...state,
  sectionDetails: action.payload,
});

const getSectionsDetailsLoad = (state) => ({
  ...state,
  sectionsDetailsLoad: !state.sectionsDetailsLoad,
});

const setCreateLessonLoading = (state) => ({
  ...state,
  createLessonLoading: !state.createLessonLoading,
});

const getLessonsListLoading = (state) => ({
  ...state,
  lessonsListLoading: !state.lessonsListLoading,
});

const setLessonsList = (state, action) => ({
  ...state,
  lessonsList: action.payload.lessons,
  lessonsCount: action.payload.count,
  lessonsListLoading: !state.lessonsListLoading,
});

const getLessonDetailsLoading = (state) => ({
  ...state,
  lessonDetailsLoading: !state.lessonDetailsLoading,
});

const setLessonDetails = (state, action) => {
  return {
    ...state,
    lessonDetails: action.payload,
  };
};

const setLessonName = (state, action) => {
  const filterLessons = state.sectionDetails.lessons.map((item) => {
    if (item._id === action.payload.id) {
      item.name = action.payload.name;
    }
    return item;
  });
  const sectionDetailsRef = state.sectionDetails;
  sectionDetailsRef.lessons = filterLessons;

  return {
    ...state,
    sectionDetailsRef: sectionDetailsRef,
  };
};
const setCreateContentLoading = (state) => ({
  ...state,
  createContentLoading: !state.createContentLoading,
});
const setUserCoursesList = (state, action) => ({
  ...state,
  usercoursesList: action.payload.courses,
  usercoursesListLoading: !state.userCoursesListLoading,
});
const getuserCoursesListLoading = (state) => ({
  ...state,
  getuserCoursesListLoading: !state.getuserCoursesListLoading,
});
const setCourseProgress = (state, action) => ({
  ...state,
  courseProgress: action.payload,
  courseProgressLoading: !state.courseProgressLoading,
});
const getCourseProgressLoading = (state) => ({
  ...state,
  courseProgressLoading: !state.courseProgressLoading,
});
const setUserProgress = (state, action) => ({
  ...state,
  userProgress: action.payload.data,
  userProgressLoading: !state.userProgressLoading,
});
const getUserProgressLoading = (state) => ({
  ...state,
  userProgressLoading: !state.userProgressLoading,
});
const setCourseCurriculum = (state, action) => ({
  ...state,
  courseCurriculum: action.payload.data,
  courseCurriculumLoading: !state.courseCurriculumLoading,
});
const getCourseCurriculumLoading = (state) => ({
  ...state,
  courseCurriculumLoading: !state.courseCurriculumLoading,
});
const setCourseUserId = (state, action) => ({
  ...state,
  courseUserId: action.payload,
});
const getCourseUserFormFieldLoading = (state) => ({
  ...state,
  courseUserFromFieldLoading: !state.courseUserFromFieldLoading,
});
//--------------
const setCourseUsersList = (state, action) => ({
  ...state,
  courseUsersList: action.payload,
  courseUsersListLoading: !state.courseUsersListLoading,
});
const getCourseUsersListLoading = (state) => ({
  ...state,
  courseUsersListLoading: !state.courseUsersListLoading,
});
const setUsersByCourseList = (state, action) => ({
  ...state,
  usersByCourseList: action.payload.enrollments,
  usersByCourseListLoading: !state.usersByCourseListLoading,
});
const getUsersByCourseListLoading = (state) => ({
  ...state,
  usersByCourseListLoading: !state.usersByCourseListLoading,
});
export const courseReducersHandlers = {
  [constants.SET_COURSES_LIST]: setCoursesList,
  [constants.GET_COURSES_LIST_LOADING]: getCoursesListLoading,
  [constants.SET_COURSE_DETAILS]: setCourseDetails,
  [constants.GET_COURSE_DETAILS_LOADING]: getCourseDetailsLoading,
  [constants.SET_CREATE_COURSE_LOADING]: setCreateCourseLoading,
  [constants.SET_CREATE_SECTION_LOADING]: setCreateSectionLoading,
  [constants.SET_SECTIONS_LIST]: setSectionsList,
  [constants.GET_SECTIONS_LIST_LOADING]: getSectionsListLoading,
  [constants.SET_SECTION_DETAILS]: setSectionDetails,
  [constants.GET_SECTION_DETAILS_LOAD]: getSectionsDetailsLoad,
  [constants.SET_CREATE_LESSON_LOADING]: setCreateLessonLoading,
  [constants.GET_LESSONS_LIST_LOADING]: getLessonsListLoading,
  [constants.SET_LESSONS_LIST]: setLessonsList,
  [constants.GET_LESSON_DETAILS_LOADING]: getLessonDetailsLoading,
  [constants.SET_LESSON_DETAILS]: setLessonDetails,
  [constants.SET_LESSON_NAME]: setLessonName,
  [constants.SET_CREATE_CONTENT_LOADING]: setCreateContentLoading,
  [constants.SET_USER_COURSES_LIST]: setUserCoursesList,
  [constants.GET_USER_COURSES_LIST_LOADING]: getuserCoursesListLoading,
  [constants.SET_COURSE_PROGRESS]: setCourseProgress,
  [constants.GET_COURSE_PROGRESS_LOAD]: getCourseProgressLoading,
  [constants.SET_USER_PROGRESS]: setUserProgress,
  [constants.GET_USER_PROGRESS_LOAD]: getUserProgressLoading,
  [constants.SET_COURSE_CURRICULUM]: setCourseCurriculum,
  [constants.GET_COURSE_CURRICULUM_LOAD]: getCourseCurriculumLoading,
  [constants.SET_COURSE_USER_ID]: setCourseUserId,
  [constants.GET_COURSE_USER_FORM_FIELD_LOADING]: getCourseUserFormFieldLoading,
  [constants.SET_COURSE_USERS_LIST]: setCourseUsersList,
  [constants.GET_COURSE_USERS_LIST_LOADING]: getCourseUsersListLoading,
  [constants.GET_USERS_BY_COURSE_LIST_LOADING]: getUsersByCourseListLoading,
  [constants.SET_USERS_BY_COURSE_LIST]: setUsersByCourseList,
};
