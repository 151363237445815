import { courseReducersHandlers } from "./allCourseReducers";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  coursesList: [],
  coursesCount: 0,
  coursesListLoading: false,
  // ---
  courseDetails: {},
  courseDetailsLoading: false,
  createCourseLoading: false,
  // ---
  createSectionLoading: false,
  sectionsList: [],
  sectionsCount: 0,
  sectionDetails: {},
  sectionsDetailsLoad: false,
  sectionsListLoading: false,
  // ----
  createLessonLoading: false,
  lessonsList: [],
  lessonsCount: 0,
  lessonsListLoading: false,
  lessonDetails: {},
  lessonName: "",
  lessonDetailsLoading: false,
  lessonComplete: "",
  // -----------
  createContentLoading: false,
  contentsList: [],
  contentsCount: 0,
  contentsListLoading: false,
  contentDetails: {},
  contentName: "",
  contentDetailsLoading: false,
  // -----------
  currentLessonIndex: 0,
  items: [],
  //-----------
  usercoursesList: [],
  usercoursesCount: 0,
  usercoursesListLoading: false,
  //----
  cousreProgress: 0,
  courseProgressLoading: false,
  userProgress: 0,
  userProgressLoading: false,
  courseCurriculum: [],
  getCourseCurriculumLoading: 0,
  courseUserId: "",
  courseUserFromFieldLoading: false,
  //-------------------
  courseUsersList: [],
  courseUsersListLoading: false,
  //-------------------
  usersByCourseList: [],
  usersByCourseListLoading: false,


};

const handlers = {
  ...courseReducersHandlers,
};

const courseReducers = createReducer(initialState, handlers);

export default courseReducers;
