import { jwtDecode } from "jwt-decode";

const getTokenExpiration = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp) {
      return decodedToken.exp * 1000; // convert to milliseconds
    }
  } catch (error) {
    console.error('Invalid token', error);
  }
  return null;
}

export const scheduleTokenExpiryLogout = (token, logoutFunction) => {
  const expirationTime = getTokenExpiration(token);
  if (expirationTime) {
    const timeLeft = expirationTime - Date.now();
    if (timeLeft >= 0) {
      setTimeout(logoutFunction, timeLeft);
    } else {
      logoutFunction(); // Token already expired
    }
  }
}

export const generateQueryParams = (query) => {
  let str = "?";
  for (var xyz in query) {
    if (query[xyz] != null) {
      str = `${str}${xyz}=${query[xyz]}&`;
    }
  }
  str = str.slice(0, -1);
  return str !== "" ? str : null;
};

export const getOptions = (array, textField) => {
  return array ? array.map((item) => {
    return { [textField]: item.name, value: item.value };
  }) : [];
}


export const handleListOrder = (data, index, signal) => {
  let temp = data[index]
  data[index] = data[index - (signal)]
  data[index - (signal)] = temp
  let new_data = data.map(item => item._id)
  return new_data;
}

export const timeConvert = (time) => {
  // Check correct time format and split into components
  time = (time &&
    time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/)) || [
      time,
    ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getSort = (array, item) => {
  return array.sort((a, b) => {
    return a[item] - b[item];
  });
};