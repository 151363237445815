import * as constants from "../constants";

const setSpeakersList = (state, action) => {
  return {
    ...state,
    speakersList: action.payload.Speakers,
    speakersCount: action.payload.count,
  }
};

const setSpeakerDetails = (state, action) => ({
  ...state,
  speakerDetails: action.payload.speaker
})

export const dataHandlers = {
  [constants.SET_SPEAKERS_LIST]: setSpeakersList,
  [constants.SET_SPEAKER_DETAILS]: setSpeakerDetails,
};
