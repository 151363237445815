import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import appReducers from '../app/reducers'
import courseReducer from "../pages/Courses/reducers";
import eventReducer from '../pages/Events/reducers'
import speakerReducer from "../pages/Speakers/reducers";
import sponsorReducer from "../pages/Sponsors/reducers";
import appointmentReducer from "../pages/Appointment/reducers";
import authReducer from "../pages/Authentication/reducers";
import dayscodeReducer from "../pages//DaysCode/reducers";
import roleReducer from "../pages/UserRole/reducers";
import clubReducer from "../pages/Club/reducers";
import quizTestReducer from '../pages/QuizTest/reducers'

export default combineReducers({
  form: formReducer,
  app: appReducers,
  course: courseReducer,
  event: eventReducer,
  speaker: speakerReducer,
  sponsor: sponsorReducer,
  appointment: appointmentReducer,
  auth: authReducer,
  dayscode: dayscodeReducer,
  role: roleReducer,
  club: clubReducer,
  quizTest: quizTestReducer,
});