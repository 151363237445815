import PermissionListTable from "./PermissionListTable";

const PermisssionCard = ({isEdit,setIsEdit , setTempId , setShowModal ,permissions,tempId}) => {
    return (
        <>
            <div className="row mx-0 mt-4 user-card table-overflow">
                <div className="col-12">
                    <div className="row mx-0 d-flex justify-content-space-between mb-4">
                    <div className="col-4 d-flex justify-content-end align-items-stretch flex-wrap mt-3">
                            <input type="text" className=" form-control form-control-solid ps-15 " placeholder="Search" />
                    </div>  
                    </div>
                </div>
                <div className="col-12  card1-body ">
                    <div className="row mx-0 d-flex flex-column ">
                        <PermissionListTable isEdit={isEdit} setIsEdit={setIsEdit} permissionss={permissions} setTempId={setTempId} setShowModal={setShowModal} tempId={tempId} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default PermisssionCard;
