export const batchStatusOptions = [
  { name: "Pending", value: "pending" },
  { name: "Draft", value: "draft" },
  { name: "Live", value: "live" },
  { name: "Hold", value: "hold" },
];
export const registrationStatusOptions = [
  { name: "Ongoing", value: "ongoing" },
  { name: "Close", value: "close" },
  { name: "Upcoming", value: "upcoming" },
  { name: "Hold", value: "hold" },
];

export const problemTopicOptions = [
  { name: "Array", value: "array" },
  { name: "String", value: "string" },
  { name: "LinkedList", value: "linkedlist" },
  { name: "Stack", value: "stack" },
  { name: "Queue", value: "queue" },
];

export const batchContentStatusOptions = [
  { name: "Draft", value: "draft" },
  { name: "Public", value: "public" },
  { name: "OnHold", value: "onhold" },
];
export const batchContentRoleOptions = [
  { name: "Admin", value: "admin" },
  { name: "Mentor", value: "mentor" },
  { name: "Assistant", value: "assistant" },
  { name: "Supervisor", value: "supervisor" },
  { name: "All", value: null },
];

export const batchParticipantStatusOptions = [
  { name: "pending", value: "pending" },
  { name: "accept", value: "accept" },
  { name: "reject", value: "reject" },
  { name: "hold", value: "hold" },
];

export const StatusOptions = [
  { name: "Pending", value: "pending" },
  { name: "Accept", value: "accept" },
  { name: "Reject", value: "reject" },
  { name: "Hold", value: "hold" },
  { name: "All", value: null },
];

export const LimitOptions = [
  { name: "20", value: 20 },
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "150", value: 150 },
  { name: "all", value: "all" },
];
export const RegistrationOptions = [
  { name: "ongoing", value: "ongoing" },
  { name: "closed", value: "closed" },
  { name: "All", value: "all" },
];

export const statusOptions = [
  { name: "ongoing", value: "ongoing" },
  { name: "hold", value: "hold" },
  { name: "all", value: "all" },
];

export const batchLevelOptions = [
  { name: "beginner", value: "beginner" },
  { name: "intermediate", value: "intermediate" },
  { name: "advance", value: "advancee" },
];

export const batchTypeOptions = [
  { name: "free", value: "free" },
  { name: "paid", value: "paid" },
];

export const batchCategoryOptions = [
  { name: "frontend", value: "frontend" },
  { name: "coding", value: "coding" },
  { name: "bootcamp", value: "bootcamp" },
  { name: "dsa", value: "dsa" },
  { name: "placement", value: "placement" },
];

export const TypeOptions = [
  { name: "free", value: "free" },
  { name: "paid", value: "paid" },
  { name: "all", value: "all" },
];
export const CategoryOptions = [
  { name: "frontend", value: "frontend" },
  { name: "coding", value: "coding" },
  { name: "bootcamp", value: "bootcamp" },
  { name: "dsa", value: "dsa" },
  { name: "placement", value: "placement" },
  { name: "all", value: "all" },
];

export const roleStatusOptions = [
  { name: "Approved", value: "free" },
  { name: "Pending", value: "paid" },
  { name: "In Process", value: "all" },
  { name: "Rejected", value: "paid" },
];
