import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoleListCard from "./RoleListCard";
import AddRoleModal from "../CreateRole/AddRoleModal";
import {  getPermissions, getRole, getRoles } from "../../../actions/operations";
import CustomLoader from "../../../../../components/sharedComponents/CustomLoader";
import FilterPopup from "./FilterPopup";


const RoleList = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isView , setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { roles,rolesLoading ,permissions} = useSelector((state)=>state.role) || {};
  const [openFilter,setOpenFilter] = useState(false);
  useEffect(() => {
    dispatch(getRoles())
    dispatch(getPermissions())
  }, [dispatch]);

  const toggleModal = () => {
    setShowModal(!showModal);
    console.log(roles)
  };
  const handleIsEdit = (id) =>{
    setIsEdit(true);
    toggleModal();
    getRole(id);
  }
  const handleIsView = () => {
    setIsView(!setIsView);
  }
  const handleCreate =() =>{
    setIsEdit(false);
    toggleModal();
  }
 
  return (
    <>
      <div className="row mx-0  role-list d-flex justify-content-center">

        <div className="col-lg-9 col-sm-11 col-12 mx-0 ">
          <div className="row mx-0 ">
            <div className="col-9 d-flex flex-column justify-content-start mx-0 px-0">
              <div className="header-role pt-3">Roles List</div>
              <div className="role-path">Home-Roles</div>
            </div>

            <div className="col-3 d-flex justify-content-end align-items-center gap-2 buttons">
              <div className="filterr">
              <button className="filter-btn  pt-1 btn btn-light" onClick={()=>setOpenFilter(!openFilter)}>
                <div >Filter</div> </button>
                <div className={openFilter ?"filter-popup":"" }><FilterPopup openFilter={openFilter} setOpenFilter={setOpenFilter}/></div>
             
              </div>
              
              {isView ? ( <></>) : (
                <> <button className="create-btn pt-1 btn btn-primary" onClick={handleCreate}>

                <div>Create</div>
              </button> </>
              ) }
              
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-sm-11 col-12 mx-0 px-0">

        <div className="row mx-0 d-flex justify-content-center gap-2 ">
      {rolesLoading ? ( <div className="d-flex justify-content-center w-100"><CustomLoader/></div>) : ( <>
        
        {roles && roles.map((item) => (
              <div key={item._id} className="col-11 col-xl-4 col-sm-6 mt-3 mx-0"  onClick={()=>setSelected(item._id)}>               
                <RoleListCard role={item} handleIsEdit={handleIsEdit} handleIsView={handleIsView}  showModal={showModal} setShowModal={setShowModal} toggleModal={toggleModal} isEdit={isEdit} setIsEdit={setIsEdit}  id={selected}/>              
              </div>
            ))}
       </>
      )}
        </div>
        </div>
      </div>
      {showModal && <AddRoleModal showModal={showModal} setShowModal={setShowModal} toggleModal={toggleModal} isEdit={isEdit} setIsEdit={setIsEdit} id={selected} permissionss={permissions}/>}

     
    </>
  );
};

export default RoleList;
