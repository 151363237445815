import * as constants from "../constants";

const getProblemsLoading = (state) => ({
  ...state,
  problemsListLoading: !state.problemsListLoading,
});

const setProblemsList = (state, action) => ({
  ...state,
  problemsList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setBatchProblemsList = (state, action) => ({
  ...state,
  batchProblemList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const getPracticeProblemsLoading = (state) => ({
  ...state,
  practiceProblemsLoading: !state.practiceProblemsLoading,
});

const setPracticeProblemsList = (state, action) => ({
  ...state,
  practiceProblems: action.payload,
  practiceProblemsLoading: !state.practiceProblemsLoading,
});

const getProblemDetailsLoading = (state) => ({
  ...state,
  problemDetailsLoading: !state.problemDetailsLoading,
});

const setProblemDetails = (state, action) => ({
  ...state,
  problemDetails: action.payload,
  problemDetailsLoading: !state.problemDetailsLoading,
});

const setProblemDetailsBlank = (state, action) => ({
  ...state,
  problemDetails: action.payload,
});

const setSolutionSubmissionLoading = (state) => ({
  ...state,
  solutionSubmissionLoading: !state.solutionSubmissionLoading,
});

const setUserSubmissionsList = (state, action) => ({
  ...state,
  submissionsCount: action.payload.count,
  submissions: action.payload.submissions,
  userTotalScore: action.payload.totalScore,
  totalQuizChallengesCompletedCount:
    action.payload.totalQuizChallengesCompletedCount,
  totalQuizChallengesCount: action.payload.totalQuizChallengesCount,
  totalLessonsCompletedCount: action.payload.totalLessonsCompletedCount,
  totalLessonsCount: action.payload.totalLessonsCount,
  totalProgress: action.payload.totalProgress,
});

const setSubmissionsLeaderboard = (state, action) => ({
  ...state,
  leaderBoard: action.payload,
});

const setSettingActiveTab = (state, action) => ({
  ...state,
  activeTab: action.payload,
});

const setAllSubmissions = (state, action) => ({
  ...state,
  allSubmissions: action.payload,
});

const setDaysUsersList = (state, action) => ({
  ...state,
  daysUsers: action.payload,
});

const apiLoading = (state) => ({
  ...state,
  daysUserDetailsLoading: !state.daysUserDetailsLoading,
});

const setDaysUserDetails = (state, action) => ({
  ...state,
  daysUserDetails: action.payload,
});

const setProgressCount = (state, action) => ({
  ...state,
  progressCount: action.payload,
});

const setLessonsList = (state, action) => ({
  ...state,
  lessonsList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setBatchLessonsList = (state, action) => ({
  ...state,
  batchLessonList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setLessonDetails = (state, action) => ({
  ...state,
  LessonDetails: action.payload,
  problemDetailsLoading: !state.problemDetailsLoading,
});

const setQuizChallengesList = (state, action) => ({
  ...state,
  quizChallengesList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setBatchQuizChallengesList = (state, action) => ({
  ...state,
  batchQuizChallengesList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setQuizChallengeDetails = (state, action) => ({
  ...state,
  quizChallengeDetails: action.payload,
  problemDetailsLoading: !state.problemDetailsLoading,
});

const setContentDetails = (state, action) => ({
  ...state,
  contentDetails: action.payload,
  lessonDetails: action.payload.lesson,
  problemDetails: action.payload.problem,
  quizchallenges: action.payload.challenges,
  currentDay: action.payload.day,
});

const setUpdatedQuizChallenges = (state, action) => ({
  ...state,
  quizchallenges: action.payload,
});

const setContentsList = (state, action) => ({
  ...state,
  contentsList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setTestCasesResponces = (state, action) => ({
  ...state,
  testCasesResponces: {
    data: [],
    isLoading: !state.testCasesResponces.isLoading,
    error: false,
  },
});

const setTestCasesResponcesSuccess = (state, action) => {
  return {
    ...state,
    testCasesResponces: {
      data: action.payload,
      isLoading: !state.testCasesResponces.isLoading,
      error: false,
    },
  };
};

const setTestCasesResponcesFailure = (state, action) => ({
  ...state,
  testCasesResponces: {
    data: [],
    isLoading: false,
    error: action.payload,
  },
});

const getQuizChallengeReportLoading = (state) => ({
  ...state,
  quizChallengeReportLoading: !state.quizChallengeReportLoading,
});

const getUserSubmissionsLoading = (state) => ({
  ...state,
  getUserSubmissionsLoading: !state.getUserSubmissionsLoading,
});

const getContentDetailsLoading = (state) => ({
  ...state,
  contentDetailsLoading: !state.contentDetailsLoading,
});

const getContentCompletedStatus = (state, action) => {
  const userName = action.payload;
  const {
    contentDetails: { challenges, lesson, problem, day },
  } = state || {};
  let countCompletedQuiz = 0;
  let lessonStatus = false;
  let submissionStatus = false;
  let totalCorrectQuiz = 0;
  let totalWrongQuiz = 0;

  challenges &&
    challenges.forEach((challenge) => {
      if (challenge && challenge.completedBy) {
        challenge.completedBy.forEach((item) => {
          if (item.userName === userName) {
            countCompletedQuiz = countCompletedQuiz + 1;
            if (item.result) {
              totalCorrectQuiz += 1;
            } else {
              totalWrongQuiz += 1;
            }
          }
        });
      }
    });

  const challengeCompletedStatus = {
    totalQuiz: challenges?.length,
    totalCompleted: countCompletedQuiz,
    totalCorrectQuiz: totalCorrectQuiz,
    totalWrongQuiz: totalWrongQuiz,
    status: challenges?.length > 0 && countCompletedQuiz >= challenges?.length,
  };

  lesson &&
    lesson.completedBy &&
    // eslint-disable-next-line array-callback-return
    lesson.completedBy.map((item) => {
      if (item.userName === userName) {
        lessonStatus = true;
      }
    });
  const lessonCompletedStatus = lessonStatus;

  let submission =
    problem &&
    problem.submissions.filter(
      (i) => i.userName === userName && i.batchId === "3",
    );
  if (submission && submission[0]) {
    submissionStatus = submission[0];
  }
  const submissionCompletedStatus = submissionStatus;

  return {
    ...state,
    contentCompletedStatus: {
      challengeCompletedStatus: challengeCompletedStatus,
      lessonCompletedStatus: lessonCompletedStatus,
      submissionCompletedStatus: submissionCompletedStatus,
      day: day,
    },
  };
};

const setLessonCompletedStatus = (state) => ({
  ...state,
  contentCompletedStatus: {
    ...state.contentCompletedStatus,
    lessonCompletedStatus: true,
  },
});

const setChallengesCompletedStatus = (state) => ({
  ...state,
  contentCompletedStatus: {
    ...state.contentCompletedStatus,
    challengeCompletedStatus: {
      ...state.contentCompletedStatus.challengeCompletedStatus,
      status: true,
    },
  },
});

const setSubmissionCompletedStatus = (state) => ({
  ...state,
  contentCompletedStatus: {
    ...state.contentCompletedStatus,
    submissionCompletedStatus: true,
  },
});

const setUserAttendancePresentLoading = (state) => ({
  ...state,
  userAttendancePresentLoading: !state.userAttendancePresentLoading,
});

const setDaysUserRegLoading = (state) => ({
  ...state,
  daysUserRegLoading: !state.daysUserRegLoading,
});

const setAllUsersFeedbacks = (state, action) => ({
  ...state,
  feedbacks: action.payload,
  feedbacksLoading: !state.feedbacksLoading,
});

const getAllUsersFeedbacksLoading = (state) => ({
  ...state,
  feedbacksLoading: !state.feedbacksLoading,
});

const setUserFeedbackLoading = (state) => ({
  ...state,
  setUserFeedbackLoading: !state.setUserFeedbackLoading,
});

const setAllUsersProgressByMentor = (state, action) => ({
  ...state,
  allUsersProgressByMentor: action.payload,
  getAllUsersProgressByMentorLoading: !state.getAllUsersProgressByMentorLoading,
});

const getAllUsersProgressByMentorLoading = (state) => ({
  ...state,
  getAllUsersProgressByMentorLoading: !state.getAllUsersProgressByMentorLoading,
});

const setAllCodeBatches = (state, action) => ({
  ...state,
  codeBatches: action.payload.batches,
  codeBatchesCount: action.payload.count,
  codeBatchesLoading: !state.codeBatchesLoading,
});

const setAllCodeBatchesLoading = (state) => ({
  ...state,
  codeBatchesLoading: !state.codeBatchesLoading,
});

const setMoreCodeBatches = (state, action) => {
  const moreBatchesList = action.payload;
  const { codeBatches } = state;

  return {
    ...state,
    codeBatches: [...codeBatches, ...moreBatchesList],
    moreCodeBatchesLoading: !state.moreCodeBatchesLoading,
  };
};

const setMoreCodeBatchesLoading = (state) => ({
  ...state,
  moreCodeBatchesLoading: !state.moreCodeBatchesLoading,
});
const setCodeBatch = (state, action) => ({
  ...state,
  codeBatchDetails: action.payload.batch,
  codeBatchDetailsSubmissions: action.payload,
  codeBatchesLoading: !state.codeBatchesLoading,
});
const resetCodeBatch = (state) => ({
  ...state,
  codeBatches: [],
});

const setCodeBatchSubmissions = (state, action) => ({
  ...state,
  codeBatchDetailsSubmissions: action.payload,
});
const resetCodeBatchSubmissions = (state) => ({
  ...state,
  allSubmissions: {},
});

const setSpeakersList = (state, action) => ({
  ...state,
  speakersList: action.payload.Speakers,
  speakersListLoading: !state.speakersListLoading,
});

const getSpeakersListLoading = (state) => ({
  ...state,
  speakersListLoading: !state.speakersListLoading,
});

const setRoadmapList = (state, action) => ({
  ...state,
  roadmapList: action.payload.roadmaps,
  speakersListLoading: !state.speakersListLoading,
});

const setMentorList = (state, action) => ({
  ...state,
  mentorList: action.payload.mentors,
});

const setBatchMentorList = (state, action) => ({
  ...state,
  batchMentorList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setBatchParticipantsList = (state, action) => ({
  ...state,
  batchParticipantsList: action.payload,
  problemsListLoading: !state.problemsListLoading,
});

const setBatchLeaderBoard = (state, action) => ({
  ...state,
  batchLeaderboard: action.payload,
  batchLeaderboardLoading: !state.batchLeaderboardLoading,
});
const getBatchLeaderboardLoading = (state) => ({
  ...state,
  batchLeaderboardLoading: !state.batchLeaderboardLoading,
});

const getCreateCodeClassLoading = (state) => ({
  ...state,
  createCodeClassLoading: !state.createCodeClassLoading,
});
const getCodeClassDetailsLoading = (state) => ({
  ...state,
  CodeClassDeatailsLoading: !state.CodeClassDeatailsLoading,
});
const setCodeClassDetails = (state, action) => ({
  ...state,
  codeClassDetails: action.payload,
  CodeClassDeatailsLoading: !state.CodeClassDeatailsLoading,
});
const setCodeClassess = (state, action) => ({
  ...state,
  codeClassess: action.payload,
  codeClassesLoading: !state.codeClassesLoading,
});
const getCodeClassesLoading = (state) => ({
  ...state,
  codeClassesLoading: !state.codeClassesLoading,
});
const setCodeClassParticipants = (state, action) => {
  return {
    ...state,
    codeClassParticipants: action.payload,
    codeClassParticipantsLoading: !state.codeClassParticipantsLoading,
  };
};

const codeClassParticipantsLoading = (state) => ({
  codeClassParticipantsLoading: !state.codeClassParticipantsLoading,
});

const setContentError = (state, action) => ({
  ...state,
  contentError: action.payload,
});

const removeContentError = (state) => ({
  ...state,
  contentError: "",
});

const setBatchContent = (state, action) => ({
  ...state,
  batchContentDetails: action.payload,
  batchContentLoading: !state.batchContentLoading,
});

const setBatchContentLoading = (state) => ({
  ...state,
  batchContentLoading: !state.batchContentLoading,
});
export const getProblemsHandlers = {
  [constants.GET_PROBLEMS_LOADING]: getProblemsLoading,
  [constants.SET_PROBLEMS_LIST]: setProblemsList,
  [constants.SET_BATCH_PROBLEMS_LIST]: setBatchProblemsList,
  [constants.GET_PRACTICE_PROBLEMS_LOADING]: getPracticeProblemsLoading,
  [constants.SET_PRACTICE_PROBLEMS_LIST]: setPracticeProblemsList,
  [constants.GET_PROBLEM_DETAILS_LOADING]: getProblemDetailsLoading,
  [constants.SET_PROBLEM_DETAILS]: setProblemDetails,
  [constants.SET_PROBLEM_DETAILS_BLANK]: setProblemDetailsBlank,
  [constants.SET_SOLUTION_SUBMISSION_LOADING]: setSolutionSubmissionLoading,
  [constants.SET_USER_SUBMISSIONS_LIST]: setUserSubmissionsList,
  [constants.SET_SUBMISSIONS_LEADERBOARD]: setSubmissionsLeaderboard,
  [constants.SET_SETTING_ACTIVE_TAB]: setSettingActiveTab,
  [constants.SET_ALL_SUBMISSIONS]: setAllSubmissions,
  [constants.SET_DAYS_USERS_LIST]: setDaysUsersList,
  [constants.SET_DAYS_USER_REG_LOADING]: setDaysUserRegLoading,
  [constants.API_LOADING]: apiLoading,
  [constants.SET_DAYS_USER_DETAILS]: setDaysUserDetails,
  [constants.SET_PROGRESS_COUNT]: setProgressCount,
  [constants.SET_LESSONS_LIST]: setLessonsList,
  [constants.SET_BATCH_LESSONS_LIST]: setBatchLessonsList,
  [constants.SET_LESSON_DETAILS]: setLessonDetails,
  [constants.SET_CONTENT_DETAILS]: setContentDetails,
  [constants.SET_QUIZ_CHALLENGES_LIST]: setQuizChallengesList,
  [constants.SET_BATCH_QUIZ_CHALLENGES_LIST]: setBatchQuizChallengesList,
  [constants.SET_QUIZ_CHALLENGE_DETAILS]: setQuizChallengeDetails,
  [constants.SET_CONTENTS_LIST]: setContentsList,
  [constants.SET_TEST_CASES_RESPONCES]: setTestCasesResponces,
  [constants.SET_TEST_CASES_RESPONCES_SUCCESS]: setTestCasesResponcesSuccess,
  [constants.SET_TEST_CASES_RESPONCES_FAILURE]: setTestCasesResponcesFailure,
  [constants.GET_QUIZ_CHALLENGE_REPORT_LOADING]: getQuizChallengeReportLoading,
  [constants.GET_USER_SUBMISSIONS_LOADING]: getUserSubmissionsLoading,
  [constants.GET_CONTENT_DETAILS_LOADING]: getContentDetailsLoading,
  [constants.GET_CONTENT_COMPLETED_STATUS]: getContentCompletedStatus,
  [constants.SET_SUBMISSION_COMPLETED_STATUS]: setSubmissionCompletedStatus,
  [constants.SET_LESSON_COMPLETED_STATUS]: setLessonCompletedStatus,
  [constants.SET_CHALLENGES_COMPLETED_STATUS]: setChallengesCompletedStatus,
  [constants.SET_USER_ATTENDANCE_PRESENT_LOADING]:
    setUserAttendancePresentLoading,
  [constants.SET_UPDATED_QUIZ_CHALLENGES]: setUpdatedQuizChallenges,
  [constants.SET_USER_FEEDBACK_LOADING]: setUserFeedbackLoading,
  [constants.SET_ALL_USERS_FEEDBACKS]: setAllUsersFeedbacks,
  [constants.GET_ALL_USERS_FEEDBACKS_LOADING]: getAllUsersFeedbacksLoading,
  [constants.GET_ALL_USERS_PROGRESS_LOADING]:
    getAllUsersProgressByMentorLoading,
  [constants.GET_ALL_USERS_PROGRESS]: setAllUsersProgressByMentor,
  [constants.GET_ALL_CODE_BATCHES]: setAllCodeBatches,
  [constants.GET_CODE_BATCH]: setCodeBatch,
  [constants.RESET_CODE_BATCH]: resetCodeBatch,
  [constants.GET_CODE_BATCH_SUBMISSIONS]: setCodeBatchSubmissions,
  [constants.GET_ALL_CODE_BATCHES_LOADING]: setAllCodeBatchesLoading,
  [constants.SET_SPEAKERS_LIST]: setSpeakersList,
  [constants.GET_SPEAKERS_LIST_LOADING]: getSpeakersListLoading,
  [constants.SET_ROADMAP_LIST]: setRoadmapList,
  [constants.SET_MENTOR_LIST]: setMentorList,
  [constants.SET_BATCH_MENTOR_LIST]: setBatchMentorList,
  [constants.SET_BATCH_PARTICIPANTS_LIST]: setBatchParticipantsList,
  [constants.SET_BATCH_LEADERBOARD]: setBatchLeaderBoard,
  [constants.GET_BATCH_LEADERBOARD_LOADING]: getBatchLeaderboardLoading,
  [constants.GET_CREATE_CODE_CLASS_LOADING]: getCreateCodeClassLoading,
  [constants.GET_CODE_CLASS_DETAILS_LOADING]: getCodeClassDetailsLoading,
  [constants.SET_CODE_CLASS_DETAILS]: setCodeClassDetails,
  [constants.SET_CODE_CLASSES]: setCodeClassess,
  [constants.GET_CODE_CLASSES_LOADING]: getCodeClassesLoading,
  [constants.SET_CODE_CLASS_PARTICIPANTS]: setCodeClassParticipants,
  [constants.GET_CODE_CLASS_PARTICIPANTS_LOADING]: codeClassParticipantsLoading,
  [constants.GET_MORE_CODE_BATCHES_LOADING]: setMoreCodeBatchesLoading,
  [constants.GET_MORE_CODE_BATCHES]: setMoreCodeBatches,
  [constants.SET_CONTENT_ERROR]: setContentError,
  [constants.RESET_CODE_BATCH_SUBMISSIONS]: resetCodeBatchSubmissions,
  [constants.REMOVE_CONTENT_ERROR]: removeContentError,
  [constants.SET_BATCH_CONTENT]: setBatchContent,
  [constants.SET_BATCH_CONTENT_LOADING]: setBatchContentLoading,
};
