import React from "react";
import PropTypes from "prop-types";
import { FaExclamationTriangle } from "react-icons/fa";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const DeleteModal = ({
  open,
  toggle,
  onSubmit = () => { },
  submitButtonName = "Okay",
  submitButtonColor = "danger",
  title = "",
  message = "Are you sure want to delete this data",

}) => (
  <Modal isOpen={open} toggle={toggle} className="delete-tutorial-modal">
    <div className="p-4 d-flex justify-content-between">
      <div className="delete-modal-header-title">
        <div className="icon-d"> <FaExclamationTriangle className="tri-icon" aria-hidden="true" />
        </div>
        <h3 className="mb-0">{title}</h3>
      </div>
      <div className="">
        <button type="button" className="close" onClick={toggle} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <ModalBody className="modal-body">
      <p> {message}</p>
    </ModalBody>
    <ModalFooter className="modal-footer px-4 py-3">
      <Button color={submitButtonColor} onClick={() => onSubmit()}>
        {submitButtonName}
      </Button>
      <Button color="secondary" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteModal.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  submitButtonName: PropTypes.string,
  submitButtonColor: PropTypes.string,
  onSubmit: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteModal;
