import * as constants from "../constants";

const setImgUploading = (state) => ({
  ...state,
  imgUploading: !state.imgUploading,
})

export const appReducersHandlers = {
  [constants.SET_IMAGE_UPLOADING]: setImgUploading,
};
