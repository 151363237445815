import { appReducersHandlers } from "./appReducers";
import { createReducer } from "../../core/reduxUtils";

const initialState = {
  imgUploading: false,
};

const handlers = {
  ...appReducersHandlers,
};

const appReducers = createReducer(initialState, handlers);

export default appReducers;
