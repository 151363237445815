import * as constants from "../constants";

const setLoginUserLoading = (state) => ({
    ...state,
    loginUserLoading: !state.loginUserLoading,
  });

const setUserProfileLoading = (state) => ({
    ...state,
    userProfileLoading: !state.userProfileLoading,
  });
const setReviewListLoading = (state) => ({
   ...state,
   reviewsLoading: !state.reviewsLoading,
})
const setUsersListLoading = (state) => ({
  ...state,
  usersListLoading: !state.usersListLoading,
})
export const loadingHandlers = {
    [constants.SET_LOGIN_USER_LOADING]: setLoginUserLoading,
    [constants.SET_USER_PROFILE_LOADING]: setUserProfileLoading,
    [constants.SET_REVIEW_LIST_LOADING]: setReviewListLoading,
    [constants.SET_USERS_LIST_LOADING]: setUsersListLoading,
  };
  