import { setEventsHandlers } from "./setEvents";
import { getEventsHandlers } from "./getEvents";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentUser: {
    userName: "akshay@25",
    email: "akshaycse25@gmail.com",
  },
  eventRegisterLoading: false,
  eventDetailsLoading: false,
  eventRegistrationsLoading: false,
  eventRegistrationsCount: 0,
  eventRegistrationsList: [],
  eventDetails: {},
  eventCountDetails: {},
  eventsList: [],
  upcomingEventsList: [],
  pastEventsList: [],
  liveEventsList: [],
  searchEventsList: [],
  searchQueryObject: {},
  showSearchResultPage: false,
  upcomingEventsListLoading: false,
  pastEventsListLoading: false,
  liveEventsListLoading: false,
  searchEventsListLoading: false,
  eventsListLoading: false,
  editEventLoading: false,
  addEventFormFieldLoading: false,
  eventFormFieldsLoading: false,
  eventFormFields: [],
  addEventTimelineLoading: false,
  eventTimelinesLoading: false,
  eventTimelines: [],
  eventTimelinesCount: 0,
  eventSpeakers: [],
  eventSpeakerDetails: {},
  eventSpeakersCount: 0,
  eventSpeakersLoading: false,
  eventSponsors: [],
  eventSponsorsCount: 0,
  eventSponsorsLoading: false,
  eventTicketList: [],
};

const handlers = {
  ...setEventsHandlers,
  ...getEventsHandlers,
};

const eventReducer = createReducer(initialState, handlers);

export default eventReducer;
