import {
  SET_CURRENT_USER,
  SET_LOGIN_USER_STATUS,
  SET_LOGIN_USER,
  SET_LOGIN_USER_ERROR,
  SET_LOGOUT_USER,
  SET_USERS_PROFILE_LIST,
  SET_USER_PROFILE,
  SET_REVIEW_LIST,
  SET_EDIT_REVIEW,
  CLEAR_EDIT_REVIEW
} from "../constants";

export const setCurrentUser = (data) => ({
  type: SET_CURRENT_USER,
  payload: data,
});

export const setLoginUser = (data) => ({
  type: SET_LOGIN_USER,
  payload: data,
});

export const setLoginUserError = (data) => ({
  type: SET_LOGIN_USER_ERROR,
  payload: data,
});

export const setLoginUserStatus = (data) => ({
  type: SET_LOGIN_USER_STATUS,
  payload: data,
});

export const setLogoutUser = (data) => ({
  type : SET_LOGOUT_USER,
  payload: data,
})

export const setUsersProfileList = (data) => ({
  type: SET_USERS_PROFILE_LIST,
  payload: data,
})

export const setUserProfile = (data) => ({
  type: SET_USER_PROFILE,
  payload: data,
})

export const setReviewList = (data) => ({
  type: SET_REVIEW_LIST,
  payload: data,
})

export const setEditReview = (data) => ({
  type: SET_EDIT_REVIEW,
  payload: data,
})

export const clearEditReview = () => ({
  type: CLEAR_EDIT_REVIEW,
})