import * as constants from "../constants";

const setRole = (state, action) => ({
  ...state,
  role: action.payload,
  viewRoleLoading:!state.viewRoleLoading,
})

const setRoles = (state, action) => ({
  ...state,
  roles: action.payload,
  rolesLoading: !state.rolesLoading,
})

const setPermissions = (state, action) => ({
  ...state,
  permissions: action.payload,
  permissionsLoading: !state.permissionsLoading,
})

const setPermission = (state, action) => ({
  ...state,
  permission: action.payload,
  permissionLoading: !state.permissionLoading,
})

const setUsersList = (state ,action) => ({
  ...state,
  usersList: action.payload,
  usersListLoading:!state.usersListLoading,
})
export const getUserRoleHandlers = {
  [constants.SET_ROLE] : setRole,
  [constants.SET_ROLES] : setRoles,
  [constants.SET_PERMISSIONS] : setPermissions,
  [constants.SET_PERMISSION] : setPermission,
  [constants.SET_USERS_LIST]:setUsersList,
}
