import * as constants from "../constants";

const setEventRegistrationLoading = (state) => ({
  ...state,
  eventRegistationLoading: !state.eventRegistationLoading,
});

const setEditEventLoading = (state) => ({
  ...state,
  editEventLoading: !state.editEventLoading,
})

const setEventFromFieldLoading = (state) => ({
  ...state,
  addEventFormFieldLoading: !state.addEventFormFieldLoading,
})

const getEventFromFieldsLoading = (state) => ({
  ...state,
  eventFormFieldsLoading: !state.eventFormFieldsLoading,
})

const setEvenTimelineLoading = (state) => ({
  ...state,
  addEventTimelineLoading: !state.addEventTimelineLoading,
})

const getEvenTimelinesLoading = (state) => ({
  ...state,
  eventTimelinesLoading: !state.eventTimelinesLoading,
})

const getEvenSpeakersLoading = (state) => ({
  ...state,
  eventSpeakersLoading: !state.eventSpeakersLoading,
})

const getEvenSponsorsLoading = (state) => ({
  ...state,
  eventSponsorsLoading: !state.eventSponsorsLoading
})

export const setEventsHandlers = {
  [constants.SET_EVENT_REGISTRATION_LOADING]: setEventRegistrationLoading,
  [constants.SET_EDIT_EVENT_LOADING]: setEditEventLoading,
  [constants.SET_EVENT_FORM_FIELD_LOADING]: setEventFromFieldLoading,
  [constants.GET_EVENT_FORM_FIELDS_LOADING]: getEventFromFieldsLoading,
  [constants.SET_EVENT_TIMELINE_LOADING]: setEvenTimelineLoading,
  [constants.GET_EVENT_TIMELINES_LOADING]: getEvenTimelinesLoading,
  [constants.GET_EVENT_SPEAKERS_LOADING]: getEvenSpeakersLoading,
  [constants.GET_EVENT_SPONSORS_LOADING]: getEvenSponsorsLoading,
};
