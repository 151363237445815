import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRole, editRole, getRole } from '../../../actions/operations';
import { Container, Form, FormGroup, Label, Input, Table, Button } from 'reactstrap';
import { getModules } from '../utils';

const CreateRoleForm = ({ isEdit, toggleModal, setIsEdit, id ,permissionss}) => {
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState(null);
  const dispatch = useDispatch();
  const { role} = useSelector((state) => state.role);
  const modules = getModules(permissionss)
  useEffect(() => {
    if (isEdit) {
      dispatch(getRole(id));
    } else {
      initializePermissions({});
    }
  }, [dispatch,isEdit]);

  useEffect(() => {
    if (isEdit && role) {
      setRoleName(role.roleName);
      initializePermissions(role);
    }
  }, [role, isEdit]);

  const initializePermissions = (role) => {
    const initialPermissions = modules.reduce((acc, module) => {
      acc[module.module] = module.access.reduce((a, permission) => {
        a[permission] = role.permissions?.find(p => p.module === module.module)?.access.includes(permission) || false;
        return a;
      }, {});
      return acc;
    }, {});
    setPermissions(initialPermissions);
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handlePermissionChange = (module, permission) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [module]: {
        ...prevPermissions[module],
        [permission]: !prevPermissions[module][permission],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = modules.map((module) => ({
      module: module.module,
      access: Object.entries(permissions[module.module])
        .filter(([_, value]) => value)
        .map(([key]) => key),
    }));
    const data = {
      ...role,
      roleName: roleName,
      permissions: formattedData,
    };
    if (isEdit) {
      try {
        dispatch(editRole(data));
        setIsEdit(false);
        
      } catch (err) {
        console.error(err);
        // Handle errors appropriately (e.g., display an error message)
      }
      setPermissions(null);
    } else {
      try {
        dispatch(createRole(data));
      } catch (err) {
        console.error(err);
        // Handle errors appropriately (e.g., display an error message)
      }
    }
    toggleModal();
  };

  if (!permissions) {
    return <div>Loading...</div>; // Display a loading message while fetching data
  }

  return (
    <Form onSubmit={handleSubmit} className="mt-4">
    <Container>
      <FormGroup>
        <Label for="roleName">Role Name:</Label>
        <Input
          type="text"
          value={roleName}
          onChange={handleRoleNameChange}
          id="roleName"
        />
      </FormGroup>
      <Table  hover>
        <tbody>
          {modules.map((module, moduleIndex) => (
            <tr key={moduleIndex}>
              <td>{module.module}</td>
              {module.access.map((permission, permissionIndex) => (
                <td key={permissionIndex}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={permissions[module.module][permission] || false}
                        onChange={() => handlePermissionChange(module.module, permission)}
                      />
                      {permission}
                    </Label>
                  </FormGroup>
                </td>
              ))}
              {/* Adding empty columns if less than 4 permissions */}
              {Array.from({ length: Math.max(0, 4 - module.access.length) }, (_, index) => (
                <td key={`empty-${index}`} />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Button type="submit" color="primary">{isEdit? <>Save</> :<>Submit</>}</Button>
    </Container>
  </Form>





  );
};

export default CreateRoleForm;
