import {

  SET_PERMISSION,
  SET_PERMISSIONS,
  SET_ROLE,
  SET_ROLES,
  SET_USERS_LIST,
} from "../constants";

export const setRole = (data) => ({
  type: SET_ROLE,
  payload: data,
});

export const setRoles = (data) => ({
  type: SET_ROLES,
  payload: data,
})

export const setPermissions = (data) => ({
  type: SET_PERMISSIONS,
  payload: data,
})

export const setPermission = (data) => ({
  type: SET_PERMISSION,
  payload: data,
})

export const setUsersList = (data) => ({
  type: SET_USERS_LIST,
  payload: data,
})