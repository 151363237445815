import { createReducer } from "../../../core/reduxUtils";
import { getUserRoleHandlers } from "./getUserRole";
import { setUserRoleHandlers } from "./setUserRole";

const initialState = {
    currentUser: {
        userName: "akshay@25",
        email: "akshaycse25@gmail.com"
      },
      role : {},
      roles :[],
      addRoleFormFieldLoading : false,
      roleDetailsLoading:false,
      rolesLoading : false,
      deleteRoleLoading : false,
      viewRoleLoading : false,
      permisson :{},
      permissions : [],
      permissionLoading : false,
      permissionsLoading : false,
      deletePermissionLoading : false,
      usersList: [],
      usersListLoading: false,
}

const handlers = {
    ...getUserRoleHandlers,
    ...setUserRoleHandlers
  };
  
  const roleReducer = createReducer(initialState, handlers);
  
  export default roleReducer;
  