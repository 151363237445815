import { useEffect, useState } from "react";
import FilterPopup from "../Role/RoleList/FilterPopup";
import PermisssionCard from "./PermissionCard";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../actions/operations";
import AddPermissionModal from "./AddPermissionModal";


const PermissionsList = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [showModal , setShowModal] = useState(false);
  const [isEdit , setIsEdit] = useState(false);
  const [tempId, setTempId] = useState(null);
  const{permissions ,permissionsLoading}= useSelector((state)=>state.role)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch])
 
  const toggleModal = () => {
    setShowModal(!showModal);
  }
  return (
    <>
      <div className="row mx-0 role-list d-flex justify-content-center">

        <div className="col-lg-9 col-sm-11 col-12 mx-0 px-0">
          <div className="row mx-0 ">
            <div className="col-10 d-flex flex-column justify-content-start">
              <div className="header-role pt-3">Permissions List</div>
              <div className="role-path">Home-Permissions</div>
            </div>

            <div className="col-2 d-flex justify-content-end align-items-center gap-3 gap-lg-4 buttons">
              <div className="filterr">
                <button className="filter-btn  pt-1 btn btn-light" onClick={() => setOpenFilter(!openFilter)}>
                  <div >Filter</div> </button>
                <div className={openFilter ? "filter-popup" : ""}><FilterPopup openFilter={openFilter} setOpenFilter={setOpenFilter} /></div>
              </div>
              <button className="create-btn pt-1 btn btn-primary" onClick={()=>{setShowModal(true)}}>

                <div>Create</div>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-sm-11 col-12 mx-0 px-0">
          <div className="row mx-0 d-flex justify-content-evenly gap-4 ">
{permissionsLoading ? (<> Loading... </>):(
            <div className="col-12 mt-3">
              <PermisssionCard setIsEdit={setIsEdit}  setTempId={setTempId} isEdit={isEdit} setShowModal={setShowModal} permissions={permissions} tempId={tempId}/>
            </div>)}
          </div>
        </div>
      </div>
      {showModal && <AddPermissionModal showModal={showModal} toggleModal={toggleModal} setShowModal={setShowModal} isEdit={isEdit} setIsEdit={setIsEdit} tempId={tempId}/>}
    </>
  );
};

export default PermissionsList;
