import React  from "react";
import { ModalFooter, ModalHeader, Modal, ModalBody } from "reactstrap";
import CreateRoleForm from "./CraeteRoleForm";

const AddRoleModal = ({ showModal, setShowModal, toggleModal ,isEdit,role ,setIsEdit,id ,permissionss}) => {

  return (
    <div >
      <Modal centered isOpen={showModal} toggle={toggleModal}>
        <ModalHeader >
          <div className="modal1-header">Add a Role </div>
        </ModalHeader>
        <hr />
        <ModalBody>
         <CreateRoleForm isEdit={isEdit }  toggleModal={toggleModal} setIsEdit={setIsEdit} id={id} permissionss={permissionss}/>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center align-items-center'>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddRoleModal;