export const SET_ROLE_LOADING = "SET_ROLE_LOADING";
export const SET_ROLE_FORM_FIELD_LOADING="SET_ROLE_FORM_FIELD_LOADING"
export const SET_ROLE = "SET_ROLE"
export const SET_ROLES = "SET_ROLES"
export const SET_ROLES_LOADING ="SET_ROLES_LOADING"
export const DELETE_ROLE_LOADING ="DELETE_ROLE_LOADING"
export const VIEW_ROLE_LOADING ="VIEW_ROLE_LOADING"
export const SET_PERMISSION_LOADING="SET_PERMISSION_LOADING";
export const SET_PERMISSIONS_LOADING="SET_PERMISSIONS_LOADING";
export const SET_PERMISSIONS = "SET_PERMISSIONS"
export const SET_PERMISSION = "SET_PERMISSION"
export const DELETE_PERMISSION_LOADING ="DELETE_PERMISSION_LOADING"
export const SET_USERS_LIST ="SET_USERS_LIST"
export const USERS_LIST_LOADING = "USERS_LIST_LOADING"