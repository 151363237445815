import React, { useEffect, useState }  from "react";
import { ModalFooter, ModalHeader, Modal, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { createPermission, editPermission, getPermission } from "../../actions/operations";
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const AddPermissionModal = ({ showModal, setShowModal, toggleModal , isEdit , setIsEdit,tempId}) => {
    const [permissionName,setPermissionName] = useState();
    const dispatch=useDispatch();
    const {permission} = useSelector((state)=>state.role)
   
    useEffect(() => {
      if (isEdit) {
        dispatch(getPermission(tempId));
      } 
    }, [dispatch,isEdit ]);
  
    useEffect(() => {
      if (isEdit && permission) {
        setPermissionName(permission.title);
      }
    }, [permission, isEdit]);

    const handleSubmit = (e)=>{
        e.preventDefault();
        console.log(permissionName)
        const data = {
          ...permission,
          title:permissionName,
        }
        if(isEdit){
          dispatch(editPermission(data));
          setIsEdit(false);
        }
        else{
        dispatch(createPermission(data));
       }
        setShowModal(false);
    }

  return (
    <div >
      <Modal centered isOpen={showModal} toggle={toggleModal}>
        <ModalHeader >
          <div className="modal1-header">Add a Permission </div>
        </ModalHeader>
        <hr />
        <ModalBody>
        <Form onSubmit={handleSubmit} className="mt-4">
    <Container>
      <FormGroup>
        <Label for="permission">Permission Name:</Label>
        <Input
          type="text"
          value={permissionName}
          onChange={(e)=>{setPermissionName(e.target.value)}}
          id="permission"
          required
        />
      </FormGroup>

            <Button type="submit" color="primary">Submit</Button>
           </Container>
         </Form>
    
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center align-items-center'>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddPermissionModal;