import React, { useState } from "react";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { FaRegBell } from "react-icons/fa6";
import profile from "../../../../assets/images/svg/profile.jpg";
import logo from "../../../../assets/images/svg/datacode-logo.png";
import { Link, useNavigate } from "react-router-dom";
import {
  SET_LOGOUT_USER,
  SET_LOGIN_USER_LOADING,
} from "../../../Authentication/constants";
import { useDispatch } from "react-redux";

const CreatorStudioHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [searchInput, setSearchInput] = useState("");

  const handleLogout = () => {
    dispatch({ type: SET_LOGOUT_USER });
    dispatch({ type: SET_LOGIN_USER_LOADING });
    navigate("/login");
  };
  return (
    <>
      <div className='row mr-0 creator-studio-header'>
        <div className='col-12 col-md-12 col-lg-12 col-sm-12'>
          <div className='header border-bottom'>
            <div className='header-logo'>
              <Link to={"/"}>
                <img src={logo} alt='logo' />
              </Link>
            </div>
            <div className='header-menu'>
              <div className='search-input'>
                <label htmlFor='search'>
                  <PiMagnifyingGlassLight />
                  <input
                    type='text'
                    placeholder='search'
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className='information'>
                <FaRegBell />
                <img src={profile} alt='profile' />
                <button
                  className='btn btn-outline-danger'
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorStudioHeader;
