import axios from "axios";
import * as actions from "./actionCreator";
import toast from "react-hot-toast";
import {
 
  SET_ROLE_LOADING,
  SET_ROLES_LOADING,
  DELETE_ROLE_LOADING,
  VIEW_ROLE_LOADING,
  SET_PERMISSION_LOADING,
  SET_PERMISSIONS_LOADING,
  DELETE_PERMISSION_LOADING,
  USERS_LIST_LOADING,
} from "../constants";
import { generateQueryParams } from "../../../components/utils";
import { triggerNotifier } from "../../../components/utils/notifier";

const baseURL = process.env.REACT_APP_BASE_URL;

export const createRole= (role) => (dispatch) => {
  dispatch({ type: SET_ROLE_LOADING });
  return axios
    .post(`${baseURL}/role/create`, role)
    .then((res) => {
      if (res.status === 200) {
        triggerNotifier({
          message: "Create Role",
          type: "success",
          duration: 1000,
          icon: "👏",
        });
        console.log("role created", res);
        dispatch({ type: SET_ROLE_LOADING });
        dispatch(getRoles())
        return { success: true, role: res.data && res.data.role};
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ROLE_LOADING });
      console.log("createRole Error", error);
      triggerNotifier({
        message: "Create Role",
        type: "error",
        duration: 1000,
        icon: "⚠️",
      });
    });
};

export const getRoles =( ) => (dispatch) => {
  dispatch({ type: SET_ROLES_LOADING });
    return axios
      .get(
        `${baseURL}/role/`,
      )
      .then((res) => {
        if (res.status === 200) {
          
          dispatch(
            actions.setRoles(res.data && res.data.roles),
          );
          return { success: true, data: res.data && res.data.roles };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: SET_ROLES_LOADING });
        console.log( error);
        triggerNotifier({
          message: error.response.data.message,
          type: "error",
          duration: 2000,
          icon: "⚠️",
        });
      });
  };
 
  export const getRole =(id) => (dispatch) => {
    dispatch({ type: VIEW_ROLE_LOADING});
    return axios
      .get(
        `${baseURL}/role/${id}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            actions.setRole(res.data && res.data.role),
          );
          return { success: true, data: res.data && res.data.role };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: VIEW_ROLE_LOADING});
        console.log(error);
        triggerNotifier({
          message: error.response.data.message,
          type: "error",
          duration: 2000,
          icon: "⚠️",
        });
      });
  };

  export const editRole = (role) => (dispatch) => {
    dispatch({ type: SET_ROLE_LOADING});
    return axios
      .patch(`${baseURL}/role/${role._id}`, { data: role })
      .then((res) => {
        if (res.status === 200) {
          triggerNotifier({
            message: "Role Edited",
            type: "success",
            duration: 1000,
            icon: "👏",
          });
          dispatch({type: SET_ROLE_LOADING});
          dispatch(getRole(role._id));
          dispatch(getRoles());
          return { success: true, role: res.data && res.data.role };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({type: SET_ROLE_LOADING});
        console.log("edit Role Error", error);
        triggerNotifier({
          message: error.response.data.message,
          type: "error",
          duration: 2000,
          icon: "⚠️",
        });
      });
  };

  export const deleteRole = (id) => (dispatch) => {
    dispatch({ type: DELETE_ROLE_LOADING });
    return axios
      .delete(`${baseURL}/role/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.error("Role Deleted");
          dispatch({ type: DELETE_ROLE_LOADING});
          dispatch(getRoles());
          return { success: true, data: res.data };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_ROLE_LOADING });
        console.log("Delete Role Error", error);
        triggerNotifier({
          message: "Delete Role Error",
          type: "error",
          duration: 1000,
          icon: "⚠️",
        });
      });
  };

  export const createPermission= (permission) => (dispatch) => {
    dispatch({ type: SET_PERMISSION_LOADING });
    return axios
      .post(`${baseURL}/permission/create`, permission)
      .then((res) => {
        if (res.status === 200) {
          triggerNotifier({
            message: "Create permission",
            type: "success",
            duration: 1000,
            icon: "👏",
          });
          console.log("permission created", res);
          dispatch({ type: SET_PERMISSION_LOADING });
          dispatch(getPermissions())
          return { success: true, permission: res.data && res.data.permission};
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: SET_PERMISSION_LOADING });
        console.log("create permission Error", error);
        triggerNotifier({
          message: "Create permission",
          type: "error",
          duration: 1000,
          icon: "⚠️",
        });
      });
  };

  export const getPermissions = () => (dispatch) => {
    dispatch({ type: SET_PERMISSIONS_LOADING });
      return axios
        .get(
          `${baseURL}/permission/`,
        )
        .then((res) => {
          if (res.status === 200) {
            
            dispatch(
              actions.setPermissions(res.data && res.data.permissions),
            );
            return { success: true, data: res.data && res.data.permissions };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          console.log( error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };

    export const getPermission =(id) => (dispatch) => {
      dispatch({ type: SET_PERMISSION_LOADING});
      return axios
        .get(
          `${baseURL}/permission/${id}`,
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              actions.setPermission(res.data && res.data.permission),
            );
            return { success: true, data: res.data && res.data.permission };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          dispatch({ type: SET_PERMISSION_LOADING});
          console.log(error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };
  
    export const editPermission = (permission) => (dispatch) => {
      dispatch({ type: SET_PERMISSION_LOADING});
      return axios
        .patch(`${baseURL}/permission/${permission._id}`, { data: permission })
        .then((res) => {
          if (res.status === 200) {
            triggerNotifier({
              message: "permission Edited",
              type: "success",
              duration: 1000,
              icon: "👏",
            });
            dispatch({type: SET_PERMISSION_LOADING});
            dispatch(getPermission(permission._id));
            dispatch(getPermissions());
            return { success: true, role: res.data && res.data.permission };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          dispatch({type: SET_PERMISSION_LOADING});
          console.log("edit permission Error", error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };

    export const deletePermission = (id) => (dispatch) => {
      dispatch({ type: DELETE_PERMISSION_LOADING });
      return axios
        .delete(`${baseURL}/permission/${id}`)
        .then((res) => {
          if (res.status === 200) {
            toast.error("permission Deleted");
            dispatch({ type: DELETE_PERMISSION_LOADING});
            dispatch(getPermissions());
            return { success: true, data: res.data };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          dispatch({ type: DELETE_PERMISSION_LOADING });
          console.log("Delete permission Error", error);
          triggerNotifier({
            message: "Delete Role Error",
            type: "error",
            duration: 1000,
            icon: "⚠️",
          });
        });
    };

    export const assignUserToRole = ({role,userId}) => (dispatch) => {
      dispatch({ type: SET_PERMISSION_LOADING});
      return axios
        .patch(`${baseURL}/role/${role}/assign` ,{data:{role,userId}})
        .then((res) => {
          if (res.status === 200) {
            triggerNotifier({
              message: "User Assigned",
              type: "success",
              duration: 1000,
              icon: "👏",
            });
            dispatch({type: SET_PERMISSION_LOADING});
            dispatch(getRole(role));
            
            return { success: true, role: res.data  };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          dispatch({type: SET_PERMISSION_LOADING});
          console.log("assign user Error", error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };

    export const unassignUserToRole = ({role,userId}) => (dispatch) => {
      dispatch({ type: SET_PERMISSION_LOADING});
      return axios
        .patch(`${baseURL}/role/${role}/unassign`, { data: {role,userId} })
        .then((res) => {
          if (res.status === 200) {
            triggerNotifier({
              message: "User Unassigned",
              type: "success",
              duration: 1000,
              icon: "👏",
            });
            dispatch({type: SET_PERMISSION_LOADING});
            dispatch(getRole(role));
            
            return { success: true, role: res.data  };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          dispatch({type: SET_PERMISSION_LOADING});
          console.log("Unassign user Error", error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };

    export const getUsers = (data) => (dispatch) => {
      dispatch({ type:  USERS_LIST_LOADING});
      return axios
        .get(
          `${baseURL}/user/fetch-users${generateQueryParams({
            search: data?.search,
          })}`,
        )
        .then(({ data }) => {
          if (data.success) {
            console.log(data)
            dispatch(actions.setUsersList(data.Users));
           
            return { success: true, data: data.Users };
          }
        })
        .catch((error) => {
          dispatch({ type: USERS_LIST_LOADING });
          console.log("get days users error", error);
          triggerNotifier({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
            icon: "⚠️",
          });
        });
    };
    