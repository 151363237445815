import React from "react";
import { DownOutlined} from '@ant-design/icons';
import { Dropdown ,Space} from "antd";

export const getColumns = ({items,setTempId}) => [
  {
    dataField: "title",
    text: "Title",
    headerAlign: "left",
    align:"left",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header s-no",
    
    /* filter: textFilter() */
  },
  {
    dataField: "",
    text: "Assigned To",
    align:"left",
    headerAlign: "left",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header s-no pl-4",
    formatter: (cell, row, index) => (
     <div className="d-flex"> <span className="p-1 mx-1 Administrator">Administrator</span>  <span className="p-1 mx-1 Developer">Developer</span> <span className="p-1 mx-1 Support">Support</span> <span className="p-1 mx-1 Trial">Trial</span></div> )
  },
 
  {
    dataField: "",
    text: "Created date",
    headerAlign: "left",
    align: "left",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
    formatter: (cell, row, index) => (
      <span> 16-2-24 </span>
    ),
 
    /* filter: textFilter() */
  },
  {
    dataField: "",
    text: "Actions",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
    formatter: (cell, row ) => {
      return(
      <Dropdown menu={{ items, }} trigger={['click']}>
      <div style={{backgroundColor:"white"}} className="btn  bg-light" onClick={(e) => {
        e.preventDefault();
        setTempId(row._id)}}>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </div>
    </Dropdown> 
    )},
  },
]