import React from 'react'
import PropTypes from 'prop-types';
import { ThreeDots } from "react-loader-spinner";

const CustomLoader = ({
  color = "#797676",
}) => {
  return (
    <div className="row m-0 ">
      <div className="col-12 d-flex justify-content-center align-items-center text-center ">
        <ThreeDots
          color={color || "#797676"}
          height={50}
          width={50}
          timeout={19000} //8 secs
        />
      </div>
    </div>
  )
}

CustomLoader.propTypes = {
  color: PropTypes.string
};

export default CustomLoader