import { loadingHandlers } from "./loadingHandlers";
import { dataHandlers } from "./dataHandlers";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentUser: {
    userName: "akshay@25",
    email: "akshaycse25@gmail.com"
  },
  sponsorsListLoading: false,
  addSponsorLoading: false,
  deleteSponsorLoading: false,
  sponsorDetailsLoading: false,
  sponsorsCount: 0,
  sponsorsList: [],
  sponsorDetails: {},
};

const handlers = {
  ...loadingHandlers,
  ...dataHandlers,
};

const sponsorReducer = createReducer(initialState, handlers);

export default sponsorReducer;
