import AppRouters from "./components/AppRouters";
import CreatorStudioHeader from "../pages/Creator/components/CreatorStudioHeader";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { scheduleTokenExpiryLogout } from "../components/utils";
import { useDispatch } from "react-redux";

import { setLogoutUser } from "../pages/Authentication/actions/actionCreators";

// External CSS
import "react-widgets/styles.css";
import "react-multi-carousel/lib/styles.css";

import "../assets/scss/style.scss";
import "../assets/scss/creator.scss";
import "../assets/scss/course.scss";
import "../assets/scss/courseStudio.scss";
import "../assets/scss/sharedComponents/pagination.scss";
import "../assets/scss/sharedComponents/filter.scss";
import "../assets/scss/event.scss";
import "../assets/scss/appointment.scss";
import "../assets/scss/workshop.scss";
import "../assets/scss/webpagecontent.scss";
import "../assets/scss/sharedComponents/modal.scss";
import "../assets/scss/authentication.scss";
import "../assets/scss/dayscode.scss";
import "../assets/scss/wittyhacks.scss";
import "../assets/scss/role.scss";
import "../assets/scss/blog.scss";
import "../assets/scss/club.scss";
import "../assets/scss/quiz_Dashboard/quiz_test_dashboard.scss";
import "../assets/scss/quiz_Dashboard/quiz_board_home.scss";
import "../assets/scss/quiz_Dashboard/quiz_test_list.scss";
import "../assets/scss/quiz_Dashboard/navbar_main.scss";
import "../assets/scss/quiz_Dashboard/reviewReport.scss"
import "../assets/scss/contributor_sidenav.scss"

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const hideHeaderRoutes = ["event/dashboard/", "login"];
  const isHeaderHide = hideHeaderRoutes.some((route) =>
    location.pathname.includes(route),
  );

  const handleAutoLogout = () => {
    dispatch(setLogoutUser());
  };

  useEffect(() => {
    const token =
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token");
    if (token) {
      scheduleTokenExpiryLogout(token, handleAutoLogout);
    }
  }, []);

  return (
    <div className='App'>
      {!isHeaderHide && <CreatorStudioHeader />}
      <AppRouters />
    </div>
  );
};

export default App;
