import { loadingHandlers } from "./loadingHandlers";
import { dataHandlers } from "./dataHandlers";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentUser: {
    userName: "akshay@25",
    email: "akshaycse25@gmail.com"
  },
  appointmentEventsLoading: false,
  appointmentListLoading: false,
  appointmentEventDetailsLoading: false,
  selectedSpeakerLoading: false,
  editMentorAvaiabilityLoading: false,
  editAppointmentEventFormLoading: false,
  mentorAppointmentDeleteLoading: false,
  appointmentManageEventLoading: false,
  addSpeakerLoading: false,
  deleteSpeakerLoading: false,
  speakerDetailsLoading: false,
  mentorAvailabilitiesListLoading: false,
  appointmentEventsCount: 0,
  appointmentEvents: [],
  appointmentEventDetails: {},
  appointmentDetails: {},
  appointmentsList: [],
  appointmentsCount: 0,
  mentorAvailabilitiesList: [],
};

const handlers = {
  ...loadingHandlers,
  ...dataHandlers,
};

const appointmentReducer = createReducer(initialState, handlers);

export default appointmentReducer;
