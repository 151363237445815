import * as constants from "../constants";

const getQuizTestDetails = (state, action) => {
  return {
    ...state,
    error: null,
    currentQuizTest: action.payload.quizTest || action.payload.updatedQuizTest,
  };
};

const getQuizQuestions = (state, action) => {
  let quizQuestionsTemp = state.quizQuestions;
  quizQuestionsTemp.push(action.payload);
  return {
    ...state,
    error: null,
    quizQuestions: quizQuestionsTemp,
  };
};

const getQuizTestsList = (state, action) => ({
  ...state,
  quizTestsList: action.payload.quizTests,
})

const getQuizTestQuestions = (state,action) =>({
  ...state,
  quizTestsQuestions: action.payload.quizChallenges,
})

const getQuizQuestionsDetails = (state,action) =>({
  ...state,
  quizQuestionDetails:action.payload.quizChallenge
})


//quizOverview Reducer

const setQuizTestTaken = (state,action) => ({
  ...state,
  quizTestTakens:action.payload.reports
})

const setCandidateReportReviewDetails = (state,action) =>({
  ...state,
  candidateReportReviewDetails: action.payload.report,
})


//loaders
const setQuizTestListLoading = (state) => ({
  quizTestsListLoading: !state.quizTestsListLoading,
})

const setTestLoading = (state) => ({
  quizTestDataLoading: !state.quizTestDataLoading,
})

const setquestionDetailsLoading = (state) =>({
  quizQuestionDetailsLoading : !state.quizQuestionDetailsLoading,
   
})

const setQuizTestTakenLoading = (state) =>({
  quizTestTakenLoading : !state.quizTestTakenLoading,
})

const setCandiateReportReviewDetailsLoading = (state) =>({
  candiateReportReviewDetailsLoading : !state.candiateReportReviewDetailsLoading,
})

const setDeleteLoading =(state) =>({
  deleteLoading: !state.deleteLoading,
})

const setQuizQuestionDetailsLoading = (state) =>({
  quizQuestionDetailsLoading: !state.quizQuestionDetailsLoading,
})

const setSubmittingQuizTestLoading = (state) =>({
  quizTestSubmittingLoading: !state.quizTestSubmittingLoading,
})

const setcreatequestionLoading = (state) =>({
  ...state,
  questionCreateLoading: !state.questionCreateLoading,
})


export const getQuizTestHandlers = {
  [constants.GET_QUIZ_TEST_DETAILS]: getQuizTestDetails,
  [constants.GET_QUIZ_QUESTIONS]: getQuizQuestions,
  [constants.GET_QUIZ_TESTS_LIST]: getQuizTestsList,
  [constants.GET_TEST_QUESTIONS]: getQuizTestQuestions,
  [constants.GET_QUIZ_QUESTIONS_DETAILS]: getQuizQuestionsDetails,
  [constants.GET_QUIZ_TEST_TAKEN]: setQuizTestTaken,
  [constants.GET_CANDIDATE_DETAILS]: setCandidateReportReviewDetails,

  [constants.GET_QUIZ_TEST_LIST_LOADING]: setQuizTestListLoading,
  [constants.GET_TEST_DATA_LOADING]: setTestLoading,
  [constants.GET_TEST_QUESTION_DETAILS_LOADING]: setquestionDetailsLoading,
  [constants.GET_TEST_TAKEN_LOADING]: setQuizTestTakenLoading,
  [constants.GET_CANDIDATE_DETAILS_LOADING]: setCandiateReportReviewDetailsLoading,
  [constants.DELETE_LOADING]: setDeleteLoading,
  [constants.GET_QUIZ_QUESTION_DETAILS_LOADING]: setQuizQuestionDetailsLoading,
  [constants.SUBMITTING_QUIZ_TEST_LOADING]: setSubmittingQuizTestLoading,
  [constants.CREATE_QUESTION_LOADING]: setcreatequestionLoading,
  
};


