import * as constants from "../constants";

const getAppointmentEventsListLoading = (state) => ({
  ...state,
  appointmentEventsLoading: !state.appointmentEventsLoading,
});

const getMentorAvailabilitiesListLoading = (state) => ({
  ...state,
  mentorAvailabilitiesListLoading: !state.mentorAvailabilitiesListLoading,
})

const getAppointmentManageEventLoading = (state) => ({
  ...state,
  appointmentManageEventLoading: !state.appointmentManageEventLoading,
})

const getMentorAppointmentDeleteLoading = (state) => ({
  ...state,
  mentorAppointmentDeleteLoading: !state.mentorAppointmentDeleteLoading,
})

const getSelectedSpeakerLoading = (state) => ({
  ...state,
  selectedSpeakerLoading: !state.selectedSpeakerLoading,
})

const getEditMentorAvaiabilityLoading = (state) => ({
  ...state,
  editMentorAvaiabilityLoading: !state.editMentorAvaiabilityLoading,
})

const getEditAppointmentEventFormLoading = (state) => ({
  ...state,
  editAppointmentEventFormLoading: !state.editAppointmentEventFormLoading,
})

const getAppointmentEventDetailsLoading = (state) => ({
  ...state,
  appointmentEventDetailsLoading: !state.appointmentEventDetailsLoading,
})

const getAppointmentListLoading = (state) => ({
  ...state,
  appointmentListLoading: !state.appointmentListLoading,
})

export const loadingHandlers = {
  [constants.GET_APPOINTMENT_EVENTS_LIST_LOADING]: getAppointmentEventsListLoading,
  [constants.GET_MENTOR_AVAILABILITIES_LIST_LOADING]: getMentorAvailabilitiesListLoading,
  [constants.GET_APPOINTMENT_MANAGE_EVENT_LOADING]: getAppointmentManageEventLoading,
  [constants.GET_MENTOR_APPOINTMENT_DELETE_LOADING]: getMentorAppointmentDeleteLoading,
  [constants.GET_SELECTED_SPEAKER_LOADING]: getSelectedSpeakerLoading,
  [constants.GET_EDIT_MENTOR_AVAILABILITY_LOADING]: getEditMentorAvaiabilityLoading,
  [constants.GET_EDIT_APPOINTMENT_EVENT_FORM_LOADING]: getEditAppointmentEventFormLoading,
  [constants.GET_APPOINTMENT_EVENT_DETAILS_LOADING]: getAppointmentEventDetailsLoading,
  [constants.GET_APPOINTMENT_LIST_LOADING]: getAppointmentListLoading, 
};
