import * as constants from "../constants";

const setCurrentUser = (state, action) => {
  return {
    ...state,
    currentUser: {
      userName: action.payload.userName,
      email: action.payload.email,
      _id: action.payload._id,
      token: action.payload.token,
      courseUser: action.payload.courseUser,
      roles: action.payload.roles,
      createdAt: action.payload.createdAt,
      email_code: action.payload.email_code,
      email_verify: action.payload.email_verify,
      followers: action.payload.followers,
      myTutorialsLibrary: action.payload.myTutorialsLibrary,
      password: action.payload.password,
      updatedAt: action.payload.updatedAt,
    },
  };
};

const setLoginUser = (state, action) => {
  // local storage....
  const currentUser = {
    userName: action.payload.userName,
    email: action.payload.email,
    loginStatus: true,
    _id: action.payload._id,
    token: action.payload.token,
    courseUser: action.payload.courseUser,
    roles: action.payload.roles,
    createdAt: action.payload.createdAt,
    email_code: action.payload.email_code,
    email_verify: action.payload.email_verify,
    followers: action.payload.followers,
    myTutorialsLibrary: action.payload.myTutorialsLibrary,
    password: action.payload.password,
    updatedAt: action.payload.updatedAt,
  }

  if (action.payload.remember) {
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
    localStorage.setItem("access_token", (currentUser.token));
  }
  else {
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
    sessionStorage.setItem("access_token", (currentUser.token));
  }

  // const localStorageItem = localStorage.getItem("currentUser")
  // const tokenObject  = JSON.parse(localStorageItem)
  // console.log(tokenObject)

  return {
    ...state,
    currentUser: {
      userName: action.payload.userName,
      email: action.payload.email,
      _id: action.payload._id,
      token: action.payload.token,
      courseUser: action.payload.courseUser,
      roles: action.payload.roles,
      createdAt: action.payload.createdAt,
      email_code: action.payload.email_code,
      email_verify: action.payload.email_verify,
      followers: action.payload.followers,
      myTutorialsLibrary: action.payload.myTutorialsLibrary,
      password: action.payload.password,
      updatedAt: action.payload.updatedAt,
    }
  };
};

const setLogoutUser = (state, action) => {

  localStorage.removeItem("currentUser")
  sessionStorage.removeItem("currentUser")

  return {
    ...state,
    currentUser: {
      userName: "",
      email: "",
      _id: "",
      token: "",
      roles: [],
      courseUser: "",
      createdAt: "",
      email_code: "",
      email_verify: "",
      followers: [],
      myTutorialsLibrary: [],
      password: "",
      updatedAt: "",
    }
  };

}

const setLoginUserError = (state, action) => ({
  ...state,
  loginUserError: action.payload,
});

const setLoginUserStatus = (state, action) => ({
  ...state,
  loginStatus: action.payload,
});

const setUsersProfileList = (state, action) => ({
  ...state,
  usersData: action.payload,
}) 

const setUserProfile = (state , action) => ({
  ...state,
  userProfile: action.payload
})

const setReviewList = (state, action) => ({
  ...state,
  reviewsList: action.payload
})

const setEditReview = (state, action) => ({
  ...state,
  reviewToBeEdited: action.payload
})

const clearEditReview = (state) => ({
  ...state,
  reviewToBeEdited:{}
})
export const dataHandlers = {
  [constants.SET_CURRENT_USER]: setCurrentUser,
  [constants.SET_LOGIN_USER]: setLoginUser,
  [constants.SET_LOGIN_USER_ERROR]: setLoginUserError,
  [constants.SET_LOGIN_USER_STATUS]: setLoginUserStatus,
  [constants.SET_LOGOUT_USER]: setLogoutUser,
  [constants.SET_USERS_PROFILE_LIST]: setUsersProfileList,
  [constants.SET_USER_PROFILE]: setUserProfile,
  [constants.SET_REVIEW_LIST]: setReviewList,
  [constants.SET_EDIT_REVIEW]: setEditReview,
  [constants.CLEAR_EDIT_REVIEW]: clearEditReview,
};
