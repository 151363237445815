export const SET_PROBLEMS_LIST = "SET_PROBLEMS_LIST";
export const SET_BATCH_PROBLEMS_LIST = "SET_BATCH_PROBLEMS_LIST";
export const SET_PROBLEM_LOADING = "SET_PROBLEM_LOADING";
export const GET_PROBLEMS_LOADING = "GET_PROBLEMS_LOADING";
export const GET_PROBLEM_DETAILS_LOADING = "GET_PROBLEM_DETAILS_LOADING";
export const SET_PRACTICE_PROBLEMS_LIST = "SET_PRACTICE_PROBLEMS_LIST";
export const GET_PRACTICE_PROBLEMS_LOADING = "GET_PRACTICE_PROBLEMS_LOADING";
export const SET_PROBLEM_DETAILS = "SET_PROBLEM_DETAILS";
export const SET_PROBLEM_DETAILS_BLANK = "SET_PROBLEM_DETAILS_BLANK";
export const SET_SOLUTION_SUBMISSION_LOADING =
  "SET_SOLUTION_SUBMISSION_LOADING";
export const SET_USER_SUBMISSIONS_LIST = "SET_USER_SUBMISSIONS_LIST";
export const SET_SUBMISSIONS_LEADERBOARD = "SET_SUBMISSIONS_LEADERBOARD";
export const SET_SETTING_ACTIVE_TAB = "SET_SETTING_ACTIVE_TAB";
export const SET_ALL_SUBMISSIONS = "SET_ALL_SUBMISSIONS";
export const SET_DAYS_USER_REG_LOADING = "SET_DAYS_USER_REG_LOADING";
export const SET_DAYS_USERS_LIST = "SET_DAYS_USERS_LIST";
export const API_LOADING = "API_LOADING";
export const SET_DAYS_USER_DETAILS = "SET_DAYS_USER_DETAILS";
export const SET_PROGRESS_COUNT = "SET_PROGRESS_COUNT";
export const SET_LESSONS_LIST = "SET_LESSONS_LIST";
export const SET_BATCH_LESSONS_LIST = "SET_BATCH_LESSONS_LIST";
export const SET_LESSON_DETAILS = "SET_LESSON_DETAILS";
export const SET_QUIZ_CHALLENGES_LIST = "SET_QUIZ_CHALLENGES_LIST";
export const SET_BATCH_QUIZ_CHALLENGES_LIST = "SET_BATCH_QUIZ_CHALLENGES_LIST";
export const SET_QUIZ_CHALLENGE_DETAILS = "SET_QUIZ_CHALLENGE_DETAILS";
export const SET_CONTENT_DETAILS = "SET_CONTENT_DETAILS";
export const SET_CONTENTS_LIST = "SET_CONTENTS_LIST";
export const SET_TEST_CASES_RESPONCES = "SET_TEST_CASES_RESPONCES";
export const SET_TEST_CASES_RESPONCES_FAILURE =
  "SET_TEST_CASES_RESPONCES_FAILURE";
export const SET_TEST_CASES_RESPONCES_SUCCESS =
  "SET_TEST_CASES_RESPONCES_SUCCESS";
export const GET_QUIZ_CHALLENGE_REPORT_LOADING =
  "GET_QUIZ_CHALLENGE_REPORT_LOADING";
export const GET_USER_SUBMISSIONS_LOADING = "GET_USER_SUBMISSIONS_LOADING";
export const GET_CONTENT_DETAILS_LOADING = "GET_CONTENT_DETAILS_LOADING";
export const GET_CONTENT_COMPLETED_STATUS = "GET_CONTENT_COMPLETED_STATUS";

export const SET_SUBMISSION_COMPLETED_STATUS =
  "SET_SUBMISSION_COMPLETED_STATUS";
export const SET_LESSON_COMPLETED_STATUS = "SET_LESSON_COMPLETED_STATUS";
export const SET_CHALLENGES_COMPLETED_STATUS =
  "SET_CHALLENGES_COMPLETED_STATUS";
export const SET_USER_ATTENDANCE_PRESENT_LOADING =
  "SET_USER_ATTENDANCE_PRESENT_LOADING";
export const SET_UPDATED_QUIZ_CHALLENGES = "SET_UPDATED_QUIZ_CHALLENGES";
export const SET_USER_FEEDBACK_LOADING = "SET_USER_FEEDBACK_LOADING";
export const GET_ALL_USERS_FEEDBACKS_LOADING =
  "GET_ALL_USERS_FEEDBACKS_LOADING";
export const SET_ALL_USERS_FEEDBACKS = "SET_ALL_USERS_FEEDBACKS";
export const GET_USER_FEEDBACKS_LOADING = "GET_USER_FEEDBACKS_LOADING";
export const GET_ALL_USERS_PROGRESS_LOADING = "GET_ALL_USERS_PROGRESS_LOADING";
export const GET_ALL_USERS_PROGRESS = "GET_ALL_USERS_PROGRESS";
export const GET_ALL_CODE_BATCHES_LOADING = "GET_ALL_CODE_BATCHES_LOADING";
export const GET_ALL_CODE_BATCHES = "GET_ALL_CODE_BATCHES";
export const GET_MORE_CODE_BATCHES_LOADING = "GET_MORE_CODE_BATCHES_LOADING";
export const GET_MORE_CODE_BATCHES = "GET_MORE_CODE_BATCHES";
export const GET_CODE_BATCH = "GET_CODE_BATCH";
export const GET_CODE_BATCH_SUBMISSIONS = "GET_CODE_BATCH_SUBMISSIONS";
export const RESET_CODE_BATCH = "RESET_CODE_BATCH";
export const RESET_CODE_BATCH_SUBMISSIONS = "RESET_CODE_BATCH_SUBMISSIONS";

export const SET_SPEAKERS_LIST = "SET_SPEAKERS_LIST";
export const GET_SPEAKERS_LIST_LOADING = "GET_SPEAKERS_LIST_LOADING";
export const SET_ROADMAP_LIST = "SET_ROADMAP_LIST";
export const SET_MENTOR_LIST = "SET_MENTOR_LIST";
export const SET_BATCH_MENTOR_LIST = "SET_BATCH_MENTOR_LIST";
export const SET_BATCH_PARTICIPANTS_LIST = "SET_BATCH_PARTICIPANTS_LIST";
export const GET_PARTICIPANTS_LOADING = "GET_PARTICIPANTS_LOADING";
export const SET_BATCH_LEADERBOARD = "SET_BATCH_LEADERBOARD";
export const GET_BATCH_LEADERBOARD_LOADING = "GET_BATCH_LEADERBOARD_LOADING";
//--------------Create code class ------------------
export const GET_CREATE_CODE_CLASS_LOADING = "GET_CREATE_CODE_CLASS_LOADING";
export const GET_CODE_CLASS_DETAILS_LOADING = "GET_CODE_CLASS_DETAILS_LOADING";
export const SET_CODE_CLASS_DETAILS = "SET_CODE_CLASS_DETAILS";
export const SET_CODE_CLASSES = "SET_CODE_CLASSES";
export const GET_CODE_CLASSES_LOADING = "GET_CODE_CLASSES_LOADING";
export const GET_EDIT_CODE_CLASS_LOADING = "GET_EDIT_CODE_CLASS_LOADING";
export const GET_ADD_BATCH_IN_TO_CLASS_LOADING =
  "GET_ADD_BATCH_IN_TO_CLASS_LOADING";
export const GET_CODE_CLASS_PARTICIPANTS_LOADING =
  "GET_CODE_CLASS_PARTICIPANTS_LOADING";
export const SET_CODE_CLASS_PARTICIPANTS = "SET_CODE_CLASS_PARTICIPANTS";
export const SET_CONTENT_ERROR = "SET_CONTENT_ERROR";
export const REMOVE_CONTENT_ERROR = "REMOVE_CONTENT_ERROR";
export const SET_BATCH_CONTENT = "SET_BATCH_CONTENT";
export const SET_BATCH_CONTENT_LOADING = "SET_BATCH_CONTENT_LOADING";