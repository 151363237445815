import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import "bootstrap/dist/css/bootstrap.css";
import { Toaster } from 'react-hot-toast';

import reportWebVitals from './reportWebVitals';
// Redux
import store from "./core/store";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { setupAxiosInterceptors } from './core/axios';

setupAxiosInterceptors();  //for Managing Headers
ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <App />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            style: {
              background: 'red',
            }, 
          },
        }}
      />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
