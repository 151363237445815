import * as constants from "../constants";

const getSpeakersListLoading = (state) => ({
  ...state,
  speakersListLoading: !state.speakersListLoading,
});

const getSpeakerDetailsLoading = (state) => ({
  ...state,
  speakerDetailsLoading: !state.speakerDetailsLoading,
});

const addSpeakerLoading = (state) => ({
  ...state,
  addSpeakerLoading: !state.addSpeakerLoading,
});

const deleteSpeakerLoading = (state) => ({
  ...state,
  deleteSpeakerLoading: !state.deleteSpeakerLoading,
})

const getEventSpeakerFilterLoading = (state) => ({
  ...state,
  eventSpeakerFilterLoading: !state.eventSpeakerFilterLoading,
})

export const loadingHandlers = {
  [constants.GET_SPEAKERS_LIST_LOADING]: getSpeakersListLoading,
  [constants.GET_SPEAKER_DETAILS_LOADING]: getSpeakerDetailsLoading,
  [constants.ADD_SPEAKER_LOADING]: addSpeakerLoading,
  [constants.DELETE_SPEAKER_LOADING]: deleteSpeakerLoading,
  [constants.GET_EVENT_SPEAKER_FILTER_LOADING]: getEventSpeakerFilterLoading,
};
