import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  // const {loginStatus} = useSelector((state) => state.auth);

  const handleLogin = () => {
    let localStorageItem = localStorage.getItem("currentUser");
    let sessionStorageItem = sessionStorage.getItem("currentUser");

    if ((localStorageItem !== null ) || (sessionStorageItem !== null) ) {
      setLogin(true)
    }
    else {
      setLogin(false)
      return navigate('/login')
    }

  }

  useEffect(() => {
    handleLogin()
  }, [login])

  
  return (
    <>
   <Outlet/>
    </>
  )
};

export default PrivateRoute;
